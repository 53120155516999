import React from "react"
import { Grid,
          List,
          ListItem,
          ListItemText
} from "@mui/material/"
import Governance from "./content/governance"

export default function GovernanceDashboard() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
    >
  
      <Grid item xs={12} className="marginCenter">

          <Grid item xs={11} className="pageTitle" style={{margin: '20px auto'}} >
            
             <List>
              <ListItem>
                <ListItemText 
                  primary={<span style={{color: '#ccd7ff', fontSize: '25px' }}  >Governance Dashboard</span>}
                  secondary={<span style={{color: '#5a7bf3' }}  >Security Adminstrator</span>}
                />
              </ListItem>
            </List>
         
       
          </Grid>

          <Grid item xs={11} style={{margin: '0 auto'}} >
          
            <Governance />
    
          </Grid>

      </Grid>

    </Grid>
  )
}
