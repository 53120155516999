
import { 
  Container,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
} from '@mui/material'
import DemoFilter from './DemoFilter';
import DemoList from './DemoList';


function DemoListPage() {
  return (
      <main>
         <List style={{paddingLeft: '25px', marginTop: '10px'}} >
                <ListItem>
            
                  <ListItemText
                    primary={<div className="pageTitle" >Search our Model Registry</div>}
                  />
                </ListItem>
              </List>
          <Container sx={{ mt: 5, mb: 5 }}>
             
           
            <DemoFilter/>
            <div className="spacer30" />
            <DemoList/>
          </Container>
      </main>
  );
}

export default DemoListPage;
