import * as React from "react"
import IconButton from "@mui/material/IconButton"
import {
  Menu,
  MenuItem
} from "@mui/material/"
import {
  MENU_ICON
} from "../assets/icons/IconList"


const ITEM_HEIGHT = 75;

export default function PrimaryMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = window.location.href

  return (
    <div className="menuContainer">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{marginTop: '-10px'}}
      >
        <MENU_ICON />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        className="menuItem"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: "185px",
            color: "#4299f7",
            paddingBottom: '0px',
            borderBottom: 'solid 2px #006bb6'
          },
          
        }}
      >
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/" >Dashboard</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/dataCatalog" >Data Catalog</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/natLangSearch" >NLP Search</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/dataUpload" >Data Upload</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/modelRegistry" >Model Registry</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/workflow" >Features</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/tools" >Tools</a> </MenuItem>
      <MenuItem> <a style={{color: '#081544',textDecoration: 'none', fontSize: '18px'}} href="/admin" >Admin</a> </MenuItem>
      
      </Menu>

    </div>
  );
}
