import * as React from 'react'
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Avatar,
    Button,
    Grid
} from '@mui/material/'

import { format } from 'date-fns'

 function FormatDate(props) {
  return (

      <span>

        {
          format(new Date(props.date), 'MM/dd/yyyy')

        }

      </span>
      
    )
  }

  
  function StatusIndicator(props){
    let curStatus

    if(props.status === 'pending'){
      curStatus =  <Typography style={{color: '#c6d132'}}>{props.status}</Typography>
    }

    if(props.status === 'approved'){
      curStatus =  <Typography style={{color: '#32d14c'}}>{props.status}</Typography>
    }

    return  curStatus
  }


  export {
    FormatDate,
    StatusIndicator
   }
