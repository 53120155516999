
import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material/";
import DragAndDrop from "../components/dragndrop";
import { SummaryCard, InactiveSummaryCard } from "../components/summary";
import { NERCard, InactiveNERCard } from "../components/NER";
import { MetadataCard, InactiveMetadataCard } from "../components/metadata";
import { MyProvider } from "../myContext";
import Stack from "@mui/material/Stack";

export default function Upload() {
  const [switchView, switchLayoutsetSwitchView] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [switchActiveStateCardColor, setSwitchActiveStateCardColor] =
    React.useState(false);
  const [initSpinner, setInitSpinner] = React.useState(false);
  const [metaData, setMetaData] = React.useState();
  const [nersData, setNersData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState();
  const [state, setState] = useState({
    dataValues: [],
  });

  const switchLayout = (view) => {
    switchLayoutsetSwitchView(view);
  };

  const acitvateInitSpinner = (spin) => {
    setInitSpinner(spin);
  };

  const transitionLayout = (view) => {
    setShowLoader(view);
  };

  const getMetaData = (metaData) => {
    setSwitchActiveStateCardColor(true);
    setMetaData(metaData);
  };

  const getSummaryData = (sumData) => {
    setSummaryData(sumData);
  };

  const getNersData = (nersData) => {
    setNersData(nersData);
    setSwitchActiveStateCardColor(false);
  };

  return (
    <Grid container xs={11} className="nlpUploadContainer" >
           <Grid item xs={12} className="pageTitle" >
            NLP Upload
           <div className="spacer20" />
        </Grid>

      <Grid xs={5} marginTop={2}>
        <Stack direction="column">
          <DragAndDrop
            view={switchLayout}
            transView={transitionLayout}
            meta={getMetaData}
            summary={getSummaryData}
            nersData={getNersData}
            spinner={acitvateInitSpinner}
          />
        
        </Stack>
      </Grid>

      {switchView === false ? (
        <Grid container xs={7}>
          <Grid xs={12} padding={2}>
            <InactiveSummaryCard />
          </Grid>

          <Grid xs={6} padding={2}>
            <InactiveNERCard />
          </Grid>

          <Grid xs={6} padding={2}>
            {initSpinner === true ? (
              <InactiveMetadataCard spinner={initSpinner} />
            ) : (
              <InactiveMetadataCard />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={7}>
          <MyProvider value={{ summary: summaryData }}>
            <Grid xs={12} padding={2}>
              {switchActiveStateCardColor === true ? (
                <span>
                  <InactiveSummaryCard spinner={true} />
                </span>
              ) : (
                <SummaryCard summaryData={summaryData} />
              )}
            </Grid>

            <Grid xs={6} padding={2} style={{ marginTop: "5px" }}>
              {switchActiveStateCardColor === true ? (
                <InactiveNERCard spinner={true} />
              ) : (
                <NERCard nersData={nersData} />
              )}
            </Grid>

            <Grid xs={6} padding={2} style={{ marginTop: "5px" }}>
              <MetadataCard data={metaData} />
            </Grid>
          </MyProvider>
        </Grid>
      )}
    </Grid>
  );
}

