import React,{useEffect,useMemo} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils'
import InfoDialog from "../components/dialog"
import ApprovedData from "../data/approved_detail.json"
import {
  FormatDate,
  StatusIndicator
} from "../utils/utilities"
import axios from "axios"



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'modelname',
    numeric: false,
    disablePadding: true,
    label: 'Model Name',
  },
  {
    id: 'owner',
    numeric: true,
    disablePadding: false,
    label: 'Owner',
  },
  {
    id: 'lastupdated',
    numeric: true,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'source',
    numeric: true,
    disablePadding: false,
    label: 'Sources',
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Description',
  }

]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className='tableHead'>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{color: '#ffffff', textAlign: 'right'}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        background: '#6384f2'
      }}

   
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%',color: '#0c122d' }}
          variant="h6"
          id="tableTitle"
          component="div" >
          Datasets
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
          
            <FilterListIcon style={{color: '#0c122d', fontSize: '22px'}} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [dataFiles, setDataFiles] = React.useState([])

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  
  useEffect(() => {

    getTableData()
  }, [dataFiles])

 

   const getTableData =  () => {

    let data = JSON.stringify({
      "limit" : 10,
      "next": ""
    })

    let config = {
      method: 'post',
      url: 'http://35.84.38.192:3000/record/list',
      headers: { 
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios.request(config)
    .then(function (response) {
     
       setDataFiles(response.data.Items)

    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataFiles.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(dataFiles.length)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFiles.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(dataFiles, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  )
 


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2}}  className='table' >
        <EnhancedTableToolbar numSelected={selected.length}  />
        <TableContainer  >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
           
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataFiles.length}
            />
            <TableBody>
              {
                dataFiles.map((row, index)=>{
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.Source)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer'}}
                    
                  >
                  
                  <TableCell >
                    
                    <InfoDialog 
                      id={row.id}
                      modelName={row.meta === undefined ? <span style={{color: '#8a9be7' }} >Dataset Name Unknown</span> : row.meta.dataset.name}
                      owner={row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Owner Unknown</span> : row.meta.dataset.owner}
                      lastUpdated={  
                        row.createdAt === undefined ? '' :   <FormatDate 
                        date={row.createdAt}
                      />
                      }
                      status={row.status ===  undefined ? '' : row.status}
                      source={row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Source Unknown</span> : row.meta.dataset.source}
                      description={row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Description Unknown</span> : row.meta.dataset.description}
                    />
                  
                  </TableCell>

                  <TableCell style={{color: '#fff' }} align="right">{
                   row.status ===  undefined ? 'Unknown' :  <StatusIndicator  status={row.status} />
                  }</TableCell>

                  <TableCell style={{color: '#fff' }} align="right">
                  {
                    row.meta === undefined ? <span style={{color: '#8a9be7' }} >Dataset Name Unknown</span> : row.meta.dataset.name
                  }
                  </TableCell>
                  <TableCell style={{color: '#fff' }} align="right">
                  {
                    row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Owner Unknown</span> : row.meta.dataset.owner
                  }
                  </TableCell>
                  <TableCell style={{color: '#fff' }} align="right">{
               
                      row.createdAt === undefined ? '' :   <FormatDate 
                        date={row.createdAt}
                      />
              
                   
                  }</TableCell>

                  <TableCell style={{color: '#fff' }} align="right">
                  {
                    row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Source Unknown</span> : row.meta.dataset.source
                  }
                  </TableCell>

                  <TableCell style={{color: '#fff' }} align="right">
                  {
                    row.meta === undefined ? <span style={{color: '#8a9be7' }} >Datset Description Unknown</span> : row.meta.dataset.description
                  }
                  </TableCell>

                  </TableRow>
                  
       
                })
              }
              
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[ 10, 25]}
          component="div"
          count={dataFiles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{color: '#cad6ff'}}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
        style={{color: '#fff'}}
      />
      <div className='spacer30' />
    </Box>
  )
}
