import React, { useEffect } from "react"
import {
  Grid,
  ListItem,
  TextField,
  List,
  ListItemText,
  ListItemIcon
} from "@mui/material/"
import {
  ADMIN_ICON
} from "../assets/icons/IconList"


import {AdminCard, BasicCard, SettingsCard, UsersCard} from "../components/cards"


export default function DataCatalog() {
 
  useEffect(() => {
    
  }, [])


  return (
   
    <div style={{height: '75vh'}} >
    
      <Grid container xs={11} style={{margin: '0 auto'}} spacing={3}  >
        <Grid item xs={12}  >
          
          <List style={{paddingLeft: '25px', marginTop: '10px'}} >
            <ListItem>
             <ListItemIcon>
               <ADMIN_ICON />
              </ListItemIcon>
              <ListItemText
                primary={<div className="pageTitle" >Admin Section</div>}
              />
            </ListItem>
          </List>
          </Grid>

        <Grid item xs={4} >
          
          <AdminCard
            title={"Governance Dashboard"}
            subTitle={"Charts, Graphs and Data MGMT"}
            link={"/governance"}
            buttonLabel={"Governance"}
            message={"Click to visit the governance page, where you can view Charts, Graphs and a list of all datasets"}
          />
     

        </Grid>

        <Grid item xs={4} >
          <AdminCard
            title={"User MGMT"}
            subTitle={"User Management Dashboard"}
            buttonLabel={"User MGMT"}
            message={"User MGMT page coming soon ..."}
          />

        </Grid>

        <Grid item xs={4} >
          <AdminCard
            title={"Group MGMT"}
            subTitle={"Group Management Dashboard"}
            buttonLabel={"Group MGMT"}
            message={"Group MGMT page coming soon ..."}
          />

        </Grid>


        <Grid item xs={8} >
           <SettingsCard  />
        </Grid>

        <Grid item xs={4} >
          <UsersCard />
        </Grid>
    
        
      </Grid>
    </div>

  )
}
