import React,{useEffect} from 'react';
import {
    Button,
    Select,
    MenuItem,
    TextField,
    Typography
} from '@mui/material/'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'
import {
    CHECK_MARK_ICON
} from "../assets/icons/IconList"
import {
  StatusIndicator
} from "../utils/utilities"
import axios from "axios"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const BASE_URL = process.env.REACT_APP_BASE_URL

  const [open, setOpen] = React.useState(false);
  const [datasetSelection, setDatasetSelection] = React.useState(['sensor_data'])
  const [approval, setApproval] = React.useState(false);
  const [status, setStatus] = React.useState()

  useEffect(() => {

  }, [status])
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
    setApproval(false)
  }

  const handleUpdateApproval = () => {

    let data = JSON.stringify({
     status: 'approved'
    })
    console.log(props.id)

    let config = {
      method: 'put',
      url: `${BASE_URL}/record/${props.id}`,
      headers: { 
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios.request(config)
    .then(function (response) {
      console.log(response.data)
      setStatus('approved')
      setApproval(true)

    })
    .catch(function (error) {
      console.log(error);
    })

  }


  const handleChangeDataSetSelect = () => {
    setDatasetSelection('maintenance_logs')
  }


  return (
    <div>
    
      <Button variant="contained" onClick={handleClickOpen} style={{fontSize: '14px', width: '175px',height: '40px', background: '#6384f2'}}>
        Approval Queue
      </Button>
    
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "50%",
                background: '#0c122d',
                color: '#fff',
                border: '3px solid #6384f2',
              },
            },
          }}
      >
        
        <DialogContent>
          <Typography style={{margin: '0 auto', textAlign: 'center'}}>
          {
          approval === true ? <span className='fadePanel' style={{background:"#32d14c",fontSize: '18px', padding: '25px', width: '100%', margin: '0 auto', alignItems
          : 'center', borderRadius: '10px'}} > Data Model has been approved</span> : ''
        }
        </Typography>
        <div className='spacer20' />
     
        <DialogTitle style={{textAlign: 'center', fontSize: '24px'}}>
      
          Approval Queue &nbsp;&nbsp;&nbsp;&nbsp;</DialogTitle>
        <Typography>
         <span style={{color: '#999'}} >Last Updated:</span> {props.lastUpdated}
        </Typography>
        <div className='spacer20' />
        <Select
               labelId="demo-controlled-open-select-label"
               id="demo-controlled-open-select"
               sx={{
                    width: '75%',
                    color: '#333',
                    background: '#fff'
                }}
              value={datasetSelection}
              label="Please Choose Segmentation Type"
              onChange={()=>handleChangeDataSetSelect}
            >      
               {['sensor_data','maintenance_logs'].map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                ))}
            </Select>

            <div className='spacer20' />

            <TextField
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Dataset Name"
              value={props.modelName}
              onChange={(e) => e.target.value}
              sx={{
                width: '95%',
                margin: '0 auto'
                }}
           
            />
            <div className='spacer20' />
            <TextField
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Owner"
              value={props.owner}
              onChange={(e) => e.target.value}
              sx={{
                width: '95%',
                margin: '0 auto'
                }}
           
            /> 
            <div className='spacer20' />
            <TextField
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Source"
              value={props.source}
              onChange={(e) => e.target.value}
              sx={{
                width: '95%',
                margin: '0 auto'
                }}
           
            /> 
            <div className='spacer20' />
            <TextField
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Description"
              value={props.description}
              onChange={(e) => e.target.value}
              sx={{
                width: '95%',
                margin: '0 auto'
                }}
           
            />  
            
            <div className='spacer20' />


             <Typography>
             <span style={{color: '#999'}} >Current Status:</span> {props.status === "" ? "Unknown" : <StatusIndicator  status={props.status} />}
             </Typography>

            <div className='spacer20' />
        </DialogContent>
        <DialogActions
            sx={{paddingRight: '50px'}}
            
        >
     
          <Button variant='contained' sx={{background: '#32d14c'}} onClick={handleUpdateApproval}><CHECK_MARK_ICON /> Approve</Button>
          <Button variant='contained' color="error"  onClick={handleClose}>Reject</Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant='outlined' onClick={handleClose}>Additional Info</Button>
        </DialogActions>
        <div className='spacer20' />
      </Dialog>
    </div>
  );
}
