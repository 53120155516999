import React, { useEffect } from "react"
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  ListItemAvatar,
  Avatar
} from "@mui/material/"
import {
  BasicCard,
  DashboardImageCard,
  DashboardCard,
  SearchCard,
  AppCard
} from "../components/cards"
import {
  SEARCH_ICON,
  INSIGHT_ICON,
  CREATE_ICON,
  DOCUMENT_ICON,
  TRIANGLE_ICON
} from "../assets/icons/IconList"
import Tenjin from "../assets/images/tenjinLogo.png"
import DataBricks from "../assets/images/tools/Databricks.png"


export default function Dashboard() {
 
  return (
   
      <Grid container xs={11} className="dashboardContainer" >
      
  
          <Grid container xs={12} >
              <Grid item xs={6} >
              
                  <div className="spacer20" />
      
                 <a href="https://tenjin.cdao.saicds.com/"  target="_blank">
                  <DashboardCard
                    logo={Tenjin}
                    link={"https://tenjin.cdao.saicds.com/"}
                    logoHeight={"125px"}
                    title={<Typography style={{color: '#cfdaff', fontSize: '22px'}}  ><CREATE_ICON /> &nbsp;CREATE </Typography>}
                    subTitle={<Typography style={{color: '#fff', fontSize:'25px'}}>Data Wrangling, AI/ML & Analytics</Typography>}
                    button={"GO TO TENJIN"}
                    buttonColor={'#6785ff'}
                    slogan={<Typography ><b style={{fontSize: '20px', color: '#a1b7ff'}}> ADVANA</b> &nbsp;<small><i style={{color: '#aafbf2'}}>Powered by Tenjin</i></small></Typography>}
                  />
                  
          
                </a>

              </Grid>

              <Grid item xs={6}  >
             

                <div className="spacer20" />
                 <a href={"/workflow"} >

                 <DashboardImageCard
                   
                    logoHeight={"200px"}
                    button={"Go to Features"}
                    title={<span style={{color: '#cfdaff', fontSize: '22px'}} ><INSIGHT_ICON /> &nbsp;INSIGHTS&nbsp; <i style={{color: '#a1b7ff'}} >&</i> &nbsp; WORKFLOW </span>}
                    subTitle={<Typography style={{color: '#fff', fontSize:'20px', width: '90%'}}>Fully Supported AI-Enhanced Applications & Quarterly Reviewed Financials</Typography>}
                    details={<Typography><i style={{color: '#9a9a9a', fontSize: '18px'}}>App Library - Tools - WebApps</i></Typography>}
                  />

                </a>
              </Grid>

            
              <Grid item xs={8} >
                <Grid container xs={12}  >
     
                  <Grid item xs={11} >
                  <List >
                    <ListItem>
              
                      <ListItemText
                        primary={<Typography variant="h3" style={{fontSize: '21px', color: '#fff'}}><SEARCH_ICON color={"#3f58c6"}/>&nbsp; Search & retrieve data for use in other applications</Typography>}
                        // secondary={<Typography variant="h3" style={{fontSize: '16px', color: '#999'}} >Ingest your data sets for eterprise availability</Typography>}
                      />
                    </ListItem>
                  </List>

                  <Grid container xs={12} >

                      <Grid item xs={4} style={{padding: '5px'}} >
                        <a href="/dataCatalog" >
                          
                          <SearchCard
                            title={<Typography style={{color:'#fff',padding: '5px',paddingLeft: '5px', fontSize: '20px'}}>Data Catalog</Typography>}
                            desc={"Uploaded datasets and their descriptions, searchable by type"}
                          
                          />
                        </a>
                      </Grid>


                      <Grid item xs={4} style={{padding: '5px'}} >
                          <a href="/modelRegistry" >
                          <SearchCard 
                          title={<Typography style={{color:'#fff',padding: '5px',paddingLeft: '5px', fontSize: '20px'}}>Model Repository</Typography>}
                          desc={"Deployed and saved models, searchable by type "}
                          listColor={'#fff'}
                          isImage={"true"}
                          />
                          </a>
                      </Grid>

                      <Grid item xs={4} style={{padding: '5px'}} >
                          <a href="/natLangSearch" >
                          <SearchCard 
                          title={<Typography style={{color:'#fff',padding: '5px',paddingLeft: '5px', fontSize: '20px'}}>Natural Language Search</Typography>}
                          desc={"Search through available datasets by keyphrase"}
                          listColor={'#fff'}
                          />
                          </a>
                      </Grid>
              
                  </Grid>
            
              </Grid>
             
          </Grid>
        </Grid>

        <Grid item xs={4} >
            <Grid container xs={12} >
                  <Grid item xs={12}  >
                  <List >
                    <ListItem>
              
                      <ListItemText
                        primary={<Typography variant="h3" style={{fontSize: '21px', color: '#fff'}}>Expert Mode</Typography>}
                      />
                    </ListItem>
                  </List>
                    <a href={"https://dbc-05761092-492f.cloud.databricks.com/"} target="_blank" >
                      <BasicCard 
                      title={<Typography style={{color:'#929dff',padding: '5px',paddingLeft: '5px', fontSize: '19px'}}> <img src={DataBricks} style={{marginTop: '-10px',height: '100%', width: '100%'}} /></Typography>}
                      list={["Search & retrieve data for use in other applications"]}
                      listColor={'#fff'}
                      btnType={'outlined'}
                      color={"#ff3621"}
                      border="1px solid #ff3621"
                      />
                    </a>

                  </Grid>

                  <Grid item xs={12}  >
                  <div className="spacer10" />
                  <List >
                    <ListItem>
              
                      <ListItemText
                        primary={<Typography variant="h3" style={{fontSize: '21px', color: '#fff'}}>Training Documents</Typography>}
                      />
                    </ListItem>
                  </List>
                 
                    <a href={"https://tenjin.cdao.saicds.com/projects/ONBOARDING/wiki/4/Welcome%20To%20Tenjin"} target="_blank" >
                      <BasicCard 
                      title={ <List style={{marginTop: '-10px'}} >
                        <ListItem>
                          <ListItemAvatar>
                  
                            <DOCUMENT_ICON />
                    
                          </ListItemAvatar>
                  
                          <ListItemText
                            primary={<Typography variant="h3" style={{fontSize: '21px', color: '#fff'}}>Wiki Documents</Typography>}
                          />
                        </ListItem>
                      </List>}
                      list={["Links to Wiki pages"]}
                      listColor={'#fff'}
                      btnType={'outlined'}
                      color={"#849bff"}
                      border="1px solid #849bff"
                      />
                    </a>
                    

                  </Grid>

            </Grid>
         
   
        </Grid>

        <Grid item xs={9}  style={{margin:'50px auto'}} >
               <Typography variant="h6" style={{color: '#fff'}}>
                   Our Tools
               </Typography>
               <div className="spacer10" />
               <AppCard />
        </Grid>
        <Grid item xs={1} /> 
        <Grid item xs={2}  style={{margin:'50px auto'}} >
          
               <div className="spacer10" />
               <List>
               <ListItem>
              
                  <ListItemText
                    primary={<Typography className="links" ><a href="https://tenjin.cdao.saicds.com/" target="_blank" ><TRIANGLE_ICON />&nbsp; TENJIN PORTAL</a></Typography>}
                  
                  />
                </ListItem>
                <ListItem>
               
                  <ListItemText
                    primary={<Typography className="links" ><a href="/dataUpload" ><TRIANGLE_ICON />&nbsp;DATA UPLOAD</a></Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<Typography className="links" ><a href="/dataCatalog" ><TRIANGLE_ICON />&nbsp; DATA CATALOG</a></Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<Typography className="links" ><a href="/natLangSearch" ><TRIANGLE_ICON />&nbsp;NLP SEARCH</a></Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<Typography className="links" ><a href="/modelRegistry" ><TRIANGLE_ICON />&nbsp;MODEL REGISTRY</a></Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<Typography className="links" ><a href="/tools" ><TRIANGLE_ICON />&nbsp;TOOLS</a></Typography>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<Typography className="links" ><a href="/admin" ><TRIANGLE_ICON />&nbsp;ADMIN</a></Typography>}
                  />
                </ListItem>
               </List>
        </Grid>

      </Grid>
    
    </Grid>

  )
}
