import React, { useEffect } from "react"
import { Card, CardContent, Typography, Grid } from "@mui/material/"
import {
  SUMMARY_ICON
} from "../assets/icons/IconList"

function SummaryCard(props) {
  useEffect(() => {}, []);

  return (
    <Card
      sx={{
        maxWidth: 1050,
        boxShadow: "1px 3.5px 0px rgba(177, 177, 177, 0.4);",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          align="left"
          style={{ color: "#0f183f" }}
        >
            <SUMMARY_ICON />&nbsp; 
          Summary
        </Typography>
        
        <Typography
          align="justify"
          style={{ padding: "25px", color: "#64696f" }}
        >
          {props.summaryData}
        </Typography>
      </CardContent>
    </Card>
  );
}

function InactiveSummaryCard(props) {
  return (
    <Card
      sx={{
        maxWidth: 1050
      }}
      style={{
        backgroundColor: "#2f388e",
        color: "#aafbf2",
        opacity: '0.3'
        
      }}
    >
      <CardContent>
        <Typography  gutterBottom variant="h5" component="div" align="center">
          Summary
        </Typography>
        <Grid
          container
          xs={12}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {props.spinner === true ? (
              <Grid item xs={12}>
                <div class="loadingContainer">
                  <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="spacer" />
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export { SummaryCard, InactiveSummaryCard };
