import * as React from "react";
import {
  Card,
  Chip,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardActionArea,
  CardMedia,
  Collapse,
  Button,
  List,
  ListItem,
  FormGroup,
  Switch,
  FormControlLabel,
  ListItemText,
  Box,
  TextField
} from "@mui/material"
import { styled } from "@mui/material/styles"
import {AlignItemsList, FolderList} from "./list"
import {CustomizedTooltips} from "../components/toolTip"
import {
  SETTING_ICON
} from "../assets/icons/IconList"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormatDate
} from "../utils/utilities"
import DataBricks from "../assets/images/tools/DB.png"
import DataRobot from "../assets/images/tools/Datarobot.png"
import Scala from "../assets/images/tools/Scala.png"
import Alteryx from "../assets/images/tools/Alteryx.png"
import ApacheSolr from "../assets/images/tools/Apache Solr.png"
import Api from "../assets/images/tools/API.png"
import Qlik from "../assets/images/tools/Qlik.png"
import SQL from "../assets/images/tools/SQL.png"
import Python from "../assets/images/tools/Python.png"
import QueryBuilder from "../assets/images/tools/Query Builder.png"
import { APP_ICON } from "../assets/icons/IconList"


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));


function BasicCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer" >
     
    <Card style={{background: '#0f183f', borderRadius: '10px',color:'#aafbf2', height: '100px', width: '100%'}}  >
 
      <CardContent   >
        <Grid container xs={12} >
          <Grid item xs={8}>
            <Typography variant="h5"  >
              
              {props.title}
        
          </Typography>
          </Grid>

          <Grid item xs={4} style={{textAlign: 'right'}}>
            <Button
              variant={props.btnType}
              style={{marginTop: '15px', color: props.color, border: props.border}}
            >
           Learn More
            </Button>
          </Grid>
        </Grid>
      
        
      </CardContent>
     
    </Card>
    </Grid>
  )
}

function SettingsCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer" >
     
    <Card style={{background: '#0f183f', borderRadius: '10px',color:'#fff', height: '300px', width: '100%'}}  >
 
      <CardContent   >
        <Grid container xs={12} >

         <Grid item xs={6}>
            <Typography variant="h5"  >
              
              Theme Settings
        
            </Typography>
            <div className="spacer20" />
         </Grid>

         <Grid item xs={6}>
            <Typography variant="h5"  >
              
              Account Settings
        
            </Typography>
         </Grid>


          <Grid item xs={5} style={{margin: '0 auto'}} >
            
            <FormGroup>
              
                <Card style={{background: '#9dacee'}}><CardContent><FormControlLabel control={<Switch style={{color: '#172254'}} defaultChecked />} label={<Typography style={{color: '#172254'}}>
                 Dark Theme </Typography>} /><SETTING_ICON /></CardContent></Card>
                 <div className="spacer20" />
                 <Card style={{background: '#9dacee'}}><CardContent><FormControlLabel control={<Switch />} label={<Typography style={{color: '#172254'}}>Light Theme</Typography>} /><SETTING_ICON /></CardContent></Card>
            </FormGroup>
          </Grid>

          <Grid item xs={5} style={{margin: '50px auto', float: 'right'}} >
              <Typography style={{color: '#9dacee'}}>
                Current Password
              </Typography>
              <div className="spacer20" />
            <FormGroup>
              
              <FormControlLabel control={<TextField value={"*********"} style={{background: '#fff', borderRadius: '5px'}}  />} label={<span>&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="outlined" style={{border: '2px solid #5f7cff', color: '#5f7cff'}}>Change</Button></span>} />
           </FormGroup>
          </Grid>

        </Grid>
    
      </CardContent>
     
    </Card>
    </Grid>
  )
}

function UsersCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer" >
     
    <Card style={{background: '#0f183f', borderRadius: '10px',color:'#fff', height: '300px', width: '100%'}}  >
 
      <CardContent   >
        <Grid container xs={12} >
          <Grid item xs={8}>
            <Typography variant="h5"  >
              
              User
        
          </Typography>
          </Grid>

          <Grid item xs={10} style={{margin: '0 auto', fontSize: '20px', color: '#fff'}}>
            <List>
              <ListItem>
                <ListItemText 
                    primary={"User Name"}
                    secondary={<Typography style={{color: "#7b92fa"}}>John Doe</Typography>}
                />
              </ListItem>

              <ListItem>
                <ListItemText 
                    primary={"Email"}
                    secondary={<Typography style={{color: "#7b92fa"}}>JohnDoe@gmail.com</Typography> }
                />
              </ListItem>

              <ListItem>
                <ListItemText 
                    primary={"Auth"}
                    secondary={<Typography style={{color: "#787575"}} >2cbf25b52a83c335f54d685813342df52cbf25</Typography>}
                />
              </ListItem>

          
            </List>
          </Grid>
        </Grid>
      
        
      </CardContent>
     
    </Card>
    </Grid>
  )
}


function AppCard(props) {

  const imageArr = [DataBricks,DataRobot,Scala,Alteryx,ApacheSolr, Api, Qlik,SQL,QueryBuilder,Python]
  return (
    <Grid item xs={12} className="appCardContainer" >
     
    <Card style={{borderRadius: '10px',color:'#aafbf2', height: '100%', background: '#0f183f'}}  >
 
      <CardContent   >
        <Grid container xs={12} style={{margin: '0 auto'}} >

        <Grid item xs={2} style={{textAlign: 'center', marginTop: '25px', padding: '10px'}}>
            
            <a href="/tools" >
            <Typography><a href={"/tools"} >See All Our Tools</a></Typography>
            <APP_ICON />
          </a>

        </Grid>


          <Grid item xs={10} style={{margin: '10px auto'}} >
            {
              imageArr.map((image)=>{
                return   <img src={image}  style={{height: '125px', borderRadius: '10px', padding: '2px', border: '1px solid #506be7'}} />
              })
            }
      
          </Grid>

          
        </Grid>
      
        
      </CardContent>
     
    </Card>
    </Grid>
  )
}

function SearchCard(props) {
  return (
    <Grid item xs={12} className="basicCardContainer"  >

    <Card style={{background: '#0f183f', borderRadius: '10px',color:'#fff', height: '230px', width: '95%',   borderBottom: '10px solid #18255f'}} className='hoverGrid'  >
 
      <CardContent  style={{marginTop: '20px'}} >
        <Grid container xs={12} >
     
          <Grid item xs={12}>
            <Typography variant="h6" style={{fontSize: '100%'}}>
              
              {props.title}
        
          </Typography>
          </Grid>

          <Typography variant="h3" style={{fontSize: '16px', color: '#999'}} >{props.desc}</Typography>

          <Grid item xs={12} >
            <Button
              variant="contained"
              style={{marginTop: '25px', textAlign: 'center', width: '100%', color: '#fff', height: '50px', boxShadow: '#000 1px 3px 1px', background: '#3f58c6'}}
            >
              Begin Searching
            </Button>
          </Grid>
        </Grid>
      
        
      </CardContent>
     
    </Card>
    </Grid>
  )
}

function DataCatalogCard(props) {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Grid item xs={11} className="dataCatalogContainer" >
    <Card style={{background: '#fff', borderRadius: '15px'}} >
      <CardMedia
          sx={{ height: 140 }}
          image={`${process.env.PUBLIC_URL}/images/6.jpeg`}
       
        />

      <CardContent >
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Date Created</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
              props.date === undefined ? '' :   <FormatDate 
              date={props.date}
            />
            }
           
          </span>
          
        </Typography>

    
        <Typography >
          <div className="spacer10" />
          <i style={{color: '#000', fontWeight: '500'}}><b>Requested By</b></i>
          &nbsp;&nbsp;
     
          {
            props.requestedBy === undefined ? <span style={{color: '#8a9be7' }} >Requester Unknown</span> : props.requestedBy
          }

        </Typography>

        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Name</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
            props.name === undefined ? <span style={{color: '#8a9be7' }} >Name Unknown</span> : props.name.dataset.name
            }
          </span>
          
        </Typography>

        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Owner</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
            {
            props.owner === undefined ? <span style={{color: '#8a9be7' }} >Owner Unknown</span> : props.owner.dataset.owner
            }
          </span>
          
        </Typography>
        <div className="spacer20" />
        <Chip label="ADHOC" variant="outlined" style={{border: '1px solid #858fed', color: '#858fed'}}  />
            &nbsp;&nbsp;
        <Chip label="ADVANA" variant="outlined" style={{border: '1px solid #858fed', color: '#858fed'}} />
        <div className='spacer30' />
        <div className='divider' />
        <div className='spacer10' />
      
      </CardContent>
      <span>
      <CardActionArea   style={{margin: '0 auto', color: '#2d4098', textAlign: 'center',background: '#e9edff'}}   >
        <div className="spacer10" />
            <Typography><small style={{color: '#999'}} ><i>Learn More about</i></small> &nbsp;{props.name === undefined ? <span style={{color: '#8a9be7',textTransform: 'uppercase' }} >Name Unknown</span> : <span style={{textTransform: 'uppercase'}} >{props.name.dataset.name}</span>}</Typography>
          <ExpandMore
            style={{color: '#fff', margin: '5px auto', textAlign: 'center'}}
            className="expandMoreGradient" 
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon style={{width: '35px',height: '35px'}} />
          </ExpandMore>
      
      <div className='spacer10' />
      </CardActionArea>

      <Collapse in={expanded} timeout="auto" unmountOnExit style={{background: '#e9edff'}} >
        <CardContent >
        <div className='spacer30' />
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Date Updated</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
       
             {
              props.date === undefined ? '' :   <FormatDate 
              date={props.updatedDate}
            />
            }

          </span>
          
        </Typography>
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Department Team</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
          {props.team}
          </span>
          
        </Typography>
       
        <Typography >
          
          <i style={{color: '#000', fontWeight: '500'}}><b>Data Type</b></i>
          &nbsp;&nbsp;
          <span style={{color: '#2f388e', fontSize: '16px'}} >
          {props.type}
          </span>
          
        </Typography>

        <Typography >
          <div className="spacer10" />
          <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Source</b></i>
          &nbsp;&nbsp;
          <br/>
          {
           props.source === undefined ? <span style={{color: '#8a9be7' }} >Source Unknown</span> : props.source.dataset.source
          }
         
        </Typography>

        <Typography >
         
         <i style={{color: '#000', fontWeight: '500'}}><b>Dataset File</b></i>
         &nbsp;&nbsp;
         <br/>
         <span style={{color: '#2f388e', fontSize: '16px'}} >
         {props.file}
         </span>
        </Typography>

        <div className="spacer20" />

        <Typography >
         
         <i style={{color: '#000', fontWeight: '500'}}><b>Dataset Description</b></i>
         &nbsp;&nbsp;
            {
            props.desc === undefined ? <span style={{color: '#8a9be7' }} >Description Unknown</span> : props.desc.dataset.description
            }
        </Typography>
        <div className="spacer20" />
    
        </CardContent>
      </Collapse>
      </span>

    </Card>
    </Grid>
  )
}

function ImageCard(props) {
  return (
    <Card sx={{  minHeight: 300 }} style={{width: '100%'}}>
      <CardMedia
        component="img"
        height="140"
        image={props.image}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
              {
                props.title
              }
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {props.para}
        </Typography>
      </CardContent>
  
    </Card>
 
  )
}

function ImageCardList(props) {
  return (
    <Grid item xs={11} className="imageCardContainer" >
     
     
      <Card style={{borderRadius: '5px 5px 8px 8px'}} >
        <CardActionArea>
          <CardMedia style={{background: '#0f183f'}}>
            {
              props.animate === "true" ? 
            
          <div className="snow" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 1536" preserveAspectRatio="xMidYMax slice">
            <g fill="#fdcb4c" fill-opacity=".12" transform="translate(5 3)">
            <g id="snow-bottom-layer">
              <ellipse cx="6" cy="1009.5" rx="6" ry="5.5"/>
              <ellipse cx="138" cy="1110.5" rx="6" ry="5.5"/>
              <ellipse cx="398" cy="1055.5" rx="6" ry="5.5"/>
              <ellipse cx="719" cy="1284.5" rx="6" ry="5.5"/>
              <ellipse cx="760" cy="1155.5" rx="6" ry="5.5"/>
              <ellipse cx="635" cy="1459.5" rx="6" ry="5.5"/>
              <ellipse cx="478" cy="1335.5" rx="6" ry="5.5"/>
              <ellipse cx="322" cy="1414.5" rx="6" ry="5.5"/>
              <ellipse cx="247" cy="1234.5" rx="6" ry="5.5"/>
              <ellipse cx="154" cy="1425.5" rx="6" ry="5.5"/>
              <ellipse cx="731" cy="773.5" rx="6" ry="5.5"/>
              <ellipse cx="599" cy="874.5" rx="6" ry="5.5"/>
              <ellipse cx="339" cy="819.5" rx="6" ry="5.5"/>
              <ellipse cx="239" cy="1004.5" rx="6" ry="5.5"/>
              <ellipse cx="113" cy="863.5" rx="6" ry="5.5"/>
              <ellipse cx="102" cy="1223.5" rx="6" ry="5.5"/>
              <ellipse cx="395" cy="1155.5" rx="6" ry="5.5"/>
              <ellipse cx="826" cy="943.5" rx="6" ry="5.5"/>
              <ellipse cx="626" cy="1054.5" rx="6" ry="5.5"/>
              <ellipse cx="887" cy="1366.5" rx="6" ry="5.5"/>
              <ellipse cx="6" cy="241.5" rx="6" ry="5.5"/>
              <ellipse cx="138" cy="342.5" rx="6" ry="5.5"/>
              <ellipse cx="398" cy="287.5" rx="6" ry="5.5"/>
              <ellipse cx="719" cy="516.5" rx="6" ry="5.5"/>
              <ellipse cx="760" cy="387.5" rx="6" ry="5.5"/>
              <ellipse cx="635" cy="691.5" rx="6" ry="5.5"/>
              <ellipse cx="478" cy="567.5" rx="6" ry="5.5"/>
              <ellipse cx="322" cy="646.5" rx="6" ry="5.5"/>
              <ellipse cx="247" cy="466.5" rx="6" ry="5.5"/>
              <ellipse cx="154" cy="657.5" rx="6" ry="5.5"/>
              <ellipse cx="731" cy="5.5" rx="6" ry="5.5"/>
              <ellipse cx="599" cy="106.5" rx="6" ry="5.5"/>
              <ellipse cx="339" cy="51.5" rx="6" ry="5.5"/>
              <ellipse cx="239" cy="236.5" rx="6" ry="5.5"/>
              <ellipse cx="113" cy="95.5" rx="6" ry="5.5"/>
              <ellipse cx="102" cy="455.5" rx="6" ry="5.5"/>
              <ellipse cx="395" cy="387.5" rx="6" ry="5.5"/>
              <ellipse cx="826" cy="175.5" rx="6" ry="5.5"/>
              <ellipse cx="626" cy="286.5" rx="6" ry="5.5"/>
              <ellipse cx="887" cy="598.5" rx="6" ry="5.5"/>
            </g>
            </g>
            <g fill="#fdcb4c" fill-opacity=".4" transform="translate(5 3)">
            <g id="snow-top-layer">
              <circle cx="8" cy="776" r="8"/>
              <circle cx="189" cy="925" r="8"/>
              <circle cx="548" cy="844" r="8"/>
              <circle cx="685" cy="1115" r="8"/>
              <circle cx="858" cy="909" r="8"/>
              <circle cx="874" cy="1438" r="8" transform="rotate(180 874 1438)"/>
              <circle cx="657" cy="1256" r="8" transform="rotate(180 657 1256)"/>
              <circle cx="443" cy="1372" r="8" transform="rotate(180 443 1372)"/>
              <circle cx="339" cy="1107" r="8" transform="rotate(180 339 1107)"/>
              <circle cx="24" cy="1305" r="8" transform="rotate(180 24 1305)"/>
              <circle cx="8" cy="8" r="8"/>
              <circle cx="189" cy="157" r="8"/>
              <circle cx="548" cy="76" r="8"/>
              <circle cx="685" cy="347" r="8"/>
              <circle cx="858" cy="141" r="8"/>
              <circle cx="874" cy="670" r="8" transform="rotate(180 874 670)"/>
              <circle cx="657" cy="488" r="8" transform="rotate(180 657 488)"/>
              <circle cx="443" cy="604" r="8" transform="rotate(180 443 604)"/>
              <circle cx="339" cy="339" r="8" transform="rotate(180 339 339)"/>
              <circle cx="24" cy="537" r="8" transform="rotate(180 24 537)"/>
            </g>
            </g>
        </svg>
        </div>
        :
        ""
            }
       
           <img src={props.image} style={{width: '100%', borderRadius: '5px 5px 0 0'}}  />
          </CardMedia>
          
          <CardContent >
          
            <Typography variant="h6" color="text.secondary" style={{marginTop: '-16px'}}>
              {
                props.title
              }
            </Typography>

            <AlignItemsList list={props.list} /> 

          
            
          </CardContent>
        </CardActionArea>
      </Card>
      
    </Grid>
  )
}

function AdminCard(props) {
  return (
    <Grid item xs={11} className="adminCardContainer" >
     <a href={props.link} >
        <Card >
    
          <CardContent >
            
            <FolderList
              buttonLabel={props.buttonLabel}
              title={props.title}
              subTitle={props.subTitle}
              link={props.link}
              message={props.message}
            />
            <div className="spacer30" />
          
          </CardContent>
        
        </Card>
      </a>
    </Grid>
  )
}

function DashboardCard(props) {
  return (
    <Grid item xs={12} className="dashboardCardContainer" >
     <a href={props.link} >
        <Card style={{ height: '275px'}} >
 
          <CardContent  >           
            <Grid container xs={12}>
                <Grid container xs={6} >

                <Grid item xs={12} >
                  <List style={{marginTop: '-20px'}} >
                      <ListItem>
                  
                        <ListItemText
                          primary={<span style={{fontSize: '19px', color: '#fff'}}>{props.title}<br/></span>}
                          secondary={props.subTitle}
                        
                        />
                      </ListItem>
                    </List>
                </Grid>
           
                <Grid item xs={10} >
   
                   <CustomizedTooltips
                    label={props.button}
                    message={"Click to visit our main Tenjin site, where you can build AI/Ml models."}
                    link={props.link}
                    buttonColor={props.buttonColor}
                  />
                </Grid>


                </Grid>
              
                <Grid item xs={6} style={{float: 'right', textAlign: 'right'}} >
                  <img style={{height: props.logoHeight}} src={props.logo} />
                </Grid>

                <Grid item xs={12} style={{textAlign: 'right', marginTop: '-20px'}} >
                  {props.slogan}
             
                </Grid>
               
   

            </Grid>
          </CardContent>
        
        </Card>
      </a>
    </Grid>
  )
}

function DashboardImageCard(props) {
  return (
    <Card style={{background: '#445ed4', height: '275px', width: '95%', float: 'right'}} className="dashboardImageCardContainer"  >
      <Box sx={{  flexDirection: 'column',display: 'flex' }}>
        <CardContent sx={{ flex: '1 0 auto' }} className="content" >
          <Typography component="div" variant="h5">
            {props.title}
          </Typography>
          <List style={{marginTop: '-10px'}} >
           <ListItem>
             <ListItemText
                primary={<span style={{fontSize: '19px', color: '#fff'}}>{props.subTitle}</span>}
               secondary={<Typography>{props.details}<br/></Typography>}
                          
              />
      
         
            </ListItem>
             </List>
             <div className="spacer20" />
             <Button variant="outlined"
                    className="btnOutlined"
                    style={{width: '50%', height: '40px', border: '#aafbf2 1px solid', color: '#aafbf2', marginTop: '-30px', float: 'right', marginRight: '30px', borderRadius: '40px'}} >
                      {props.button}
              </Button>
        </CardContent>
      
      </Box>
    
    </Card>
   

  )
}

export { BasicCard,ImageCard, ImageCardList, DataCatalogCard,AdminCard,DashboardCard, DashboardImageCard,SearchCard,AppCard,SettingsCard, UsersCard }
