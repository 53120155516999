import * as React from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, CardContent,Card, Button, IconButton } from '@mui/material';
import { useDemoContext } from "../DemoContext"
import {
  FILTER_ICON
} from "../../../assets/icons/IconList"

function DemoFilter() {
  const { capability, setCapability, customer, setCustomer, setSearch, resultCount } = useDemoContext();

  const capabilitiesList = ["Model Registry", "AI / ML Outcomes", "Supply Chain", "Data Fusion", "Entity Analysis", "COP/SIP", "Document Management", "DDIL"];
  const customerList = ["Navy", "Army", "Air Force", "NIC", "FEDCIV", "COCOMS", "USMC"];

  const handleCapabilityChange = (event) => {
    setCapability(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
    <Grid container>
      <Grid container  spacing={4}>
        <Grid style={{textAlign:"center", color: '#aafbf2'}} xs={2}>
          <Card style={{background: '#1c275b', borderRadius: '10px',color:'#fff', height: '150px', width: '100%'}} >
            <CardContent style={{margin: '25px auto'}}>
              Total Models
              <br/>
              <IconButton style={{color: '#445ed4', textAlign: 'center', fontSize: '40px', padding: '10px'}}>
              {resultCount}
              </IconButton>
               
            </CardContent>
          </Card>
          
        </Grid> 

        <Grid item xs={1} />

        <Grid item xs={9} >
          <FormControl sx={{ m: 1,width: '100%'}} >
            <TextField 
              onChange={handleSearchChange} 
              className="inputField" 
              placeholder="Filter Registry by Text"
              fullWidth
              InputProps={{
                startAdornment: (
                  <span style={{paddingRight: '10px'}}>
                  <FILTER_ICON
                    color={"#3541ac"}
                  />
                  </span>
                )
              }}
              />
              
            <FormHelperText style={{textAlign:"left",color: '#aafbf2'}}>  <Button style={{"border":"1px solid #6384f2", color: '#6384f2', height: '25%',marginTop:"15px", width: '25%', float: 'right'}} variant="outlined" href="/modelRegistry" color="inherit">Clear</Button></FormHelperText>
          
          </FormControl>  
        </Grid>

      </Grid>
    </Grid>
    </>
  )
}

export default DemoFilter