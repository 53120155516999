import React, { useEffect, useState } from "react";
import { Card, Grid, Pagination, CardActions } from "@mui/material/";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ReactPaginate from "react-paginate";
import { RIGHT_ARROW_ICON,
         LEFT_ARROW_ICON,
         ENTITY_ICON
        } from "../assets/icons/IconList"

function NERCard(props) {
  const result = Object.keys(props.nersData).map((key) => props.nersData[key]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const endOffset = itemOffset + 4;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  useEffect(() => {
    // Fetch items from another resources.
    console.log(result);
    const endOffset = itemOffset + 7;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(result.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(result.length / 7));
  }, [itemOffset, 4]);

  const [state] = React.useState({
    dataValues: props.nersData.Entities,
  });

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 4) % result.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Card
      style={{ height: "475px" }}
      sx={{ boxShadow: "1px 3.5px 0px rgba(177, 177, 177, 0.4);" }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          align="left"
          style={{ color: "#0f183f" }}
        >
           <ENTITY_ICON />&nbsp; 
          Named Entities
        </Typography>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "15px", height: "315px" }}
        >
          {currentItems &&
            currentItems.map((item) => (
              <Grid item xs={4}>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip label={item.Entity} variant="filled">
                      {item.Entity}
                    </Chip>
                  </Stack>
                </Box>
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <CardActions >
        <ReactPaginate
          breakLabel="..."
          nextLabel={<RIGHT_ARROW_ICON />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<LEFT_ARROW_ICON />}
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </CardActions>
    </Card>
  );
}

function InactiveNERCard(props) {
  return (
    <Card
      style={{
        height: "475px",
        backgroundColor: "#2f388e",
        color: "#aafbf2",
        opacity: '0.4'
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align="center">
          Named Entities
        </Typography>

        <Grid
          container
          xs={12}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Grid item xs={12} style={{ margin: "0 auto" }}>
            {props.spinner === true ? (
              <Grid item xs={12}>
                <div class="loadingContainer">
                  <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="spacer" />
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export { NERCard, InactiveNERCard };
