
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material/";
import { CardActionArea } from "@mui/material";
import Alteryx from "../assets/images/toolsPage/Alteryx.png";
import Apache from "../assets/images/toolsPage/ApacheSolr.png";
import API from "../assets/images/toolsPage/API.png";
import Databricks from "../assets/images/toolsPage/Databricks.png";
import Datarobot from "../assets/images/toolsPage/Datarobot.png";
import FileBrowser from "../assets/images/toolsPage/FileBrowser.png";
import Python from "../assets/images/toolsPage/Python.png";
import Qlik from "../assets/images/toolsPage/Qlik.png";
import QueryBuilder from "../assets/images/toolsPage/QueryBuilder.png";
import Scala from "../assets/images/toolsPage/Scala.png";
import SQL from "../assets/images/toolsPage/SQL.png";

export default function Tools() {
  return (
    <Grid container xs={11} className="toolsPageContainer" >
       {/* AI/ML List */}
      <Grid container xs={12}spacing={3} >
 
            <Grid item xs={10} >
                     <Typography style={{ fontSize: "22px" }}>
                      AI/ML
                    </Typography>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="160"
                    image={Datarobot}
                    alt="Datarobot"
                  />
                  <CardContent >
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                      DataRobot is an automated machine learning platform that
                      empowers organizations to build, deploy, and manage machine
                      learning models at scale, even without extensive data science
                      expertise.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                  component="img"
                  height="160"
                  image={Databricks}
                  alt="Databricks"
                />
                  <CardContent style={{ height: "100%" }}>
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                      Databricks is a unified analytics platform that combines data
                      engineering, data science, and business analytics to
                      streamline and accelerate data-driven decision-making
                      processes.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>
        
      </Grid>

      <Grid xs={12} >
        <div className="spacer30" />
        <div className="divider" />
        <div className="spacer30" />
      </Grid>

      {/* Exploratory */}
      <Grid container xs={12}spacing={3} >
 
            <Grid item xs={10} >
                     <Typography style={{ fontSize: "22px" }}>
                         Exploratory
                    </Typography>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="160"
                    image={Scala}
                    alt="Datarobot"
                  />
                  <CardContent >
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                      Scala is a versatile, statically-typed programming language
                      that runs on the Java Virtual Machine (JVM), known for its
                      concise syntax and strong support for functional programming
                      paradigms.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                  component="img"
                  height="160"
                  image={Alteryx}
                  alt="Databricks"
                />
                  <CardContent style={{ height: "100%" }}>
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                      that empowers users to blend, prepare, and analyze data for
                     insights and decision-making.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                  component="img"
                  height="160"
                  image={Python}
                  alt="Databricks"
                />
                  <CardContent style={{ height: "100%" }}>
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                     Python is a versatile, high-level programming language known
                      for its simplicity, readability, and extensive library
                      support, widely used for web development, data analysis,
                      artificial intelligence, and more.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>

      </Grid>

      <Grid xs={12} >
        <div className="spacer30" />
        <div className="divider" />
        <div className="spacer30" />
      </Grid>


      {/*  Business intelligence */}
      <Grid container xs={12}spacing={3} >
 
            <Grid item xs={10} >
                     <Typography style={{ fontSize: "22px" }}>
                         Business intelligence
                    </Typography>
            </Grid>

            <Grid item xs={3} >
              <Card style={{color: '#fff', borderRadius: '10px'}}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="160"
                    image={Qlik}
                    alt="Datarobot"
                  />
                  <CardContent >
                    <Typography variant="body2" style={{fontSize: '17px'}}>
                      Qlik is a business intelligence and data analytics platform
                      that enables users to visualize and explore data, uncover
                      insights, and make data-driven decisions through interactive
                      dashboards and reports.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </Card>
            </Grid>

      </Grid>

      <Grid xs={12} >
        <div className="spacer30" />
        <div className="divider" />
        <div className="spacer30" />
      </Grid>

   {/*  Data Access */}
   <Grid container xs={12}spacing={3} >
 
    <Grid item xs={10} >
              <Typography style={{ fontSize: "22px" }}>
                  Business intelligence
            </Typography>
    </Grid>

      <Grid item xs={3} >
        <Card style={{color: '#fff', borderRadius: '10px'}}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="160"
              image={Apache}
              alt="Datarobot"
            />
            <CardContent >
              <Typography variant="body2" style={{fontSize: '17px'}}>
                  Apache Solr is an open-source search platform known for its
                  powerful full-text search and distributed search capabilities.
              </Typography>
            </CardContent>
          </CardActionArea>
          </Card>
      </Grid>

      <Grid item xs={3} >
        <Card style={{color: '#fff', borderRadius: '10px'}}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="160"
              image={API}
              alt="Datarobot"
            />
            <CardContent >
              <Typography variant="body2" style={{fontSize: '17px'}}>
                  Application Programming Interfaces are sets of rules and
                  protocols that enable different software applications to
                  communicate and interact with each other, allowing them to
                  share data and functionality.
              </Typography>
            </CardContent>
          </CardActionArea>
          </Card>
      </Grid>

        <Grid item xs={3} >
          <Card style={{color: '#fff', borderRadius: '10px'}}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="160"
                image={FileBrowser}
                alt="Datarobot"
              />
              <CardContent >
                <Typography variant="body2" style={{fontSize: '17px'}}>
                  A file browser is a software tool or user interface element
                  that allows users to navigate, view, and manage files and
                  directories on a computer or file system.
                </Typography>
              </CardContent>
            </CardActionArea>
            </Card>
        </Grid>

        <Grid item xs={3} >
          <Card style={{color: '#fff', borderRadius: '10px'}}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="160"
                image={SQL}
                alt="Datarobot"
              />
              <CardContent >
                <Typography variant="body2" style={{fontSize: '17px'}}>
                   SQL (Structured Query Language) is a domain-specific language
                  used to manage and manipulate relational databases, enabling
                  users to retrieve, update, and interact with data stored in
                  tabular format.
                </Typography>
              </CardContent>
            </CardActionArea>
            </Card>
        </Grid>

        <Grid item xs={3} >
          <Card style={{color: '#fff', borderRadius: '10px'}}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="160"
                image={QueryBuilder}
                alt="Datarobot"
              />
              <CardContent >
                <Typography variant="body2" style={{fontSize: '17px'}}>
                  A query builder is a software tool or interface that allows
                  users to construct database queries using a visual or
                  intuitive interface, abstracting away the need to write
                  complex SQL code manually.
                </Typography>
              </CardContent>
            </CardActionArea>
            </Card>
        </Grid>
    </Grid>

    </Grid>
  )
}

