import React from "react";
import { Grid } from "@mui/material";
import AdvanaLogo from "../assets/images/Advana-Logo.png"
import MenuComponent from "../components/menu"
import SideDrawer from "../components/drawer"

const Header = () => {
  return (

    <div>
      <Grid container xs={12} sm={12} md={12} lg={12}>
      
      <Grid item xs={1} >
          <div class="line right">
                <div class="scanner"></div>
            </div>
        </Grid>
        
        <Grid item xs={1} className="fadePanel" >
          
          <a href={"/"}>
            <img src={AdvanaLogo} alt="SAIC Logo"  style={{height: '60px'}} />
         
          </a>
    
          
        </Grid>
      

        <Grid xs={10} className="rightAlign" >
        
          <MenuComponent />

        </Grid>
        {/* <SideDrawer /> */}
      </Grid>
    </div>
  )
}

export default Header
