import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function ToolTip(props) {
  return (
    <div>

      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{props.message}</Typography>
          
          </React.Fragment>
        }
      >
            <Button 
          variant='contained' 
          style={{width: '75%',height: '50px',}}
          href={props.link}
          sx={{
            background: '#3f58c6',
            ':hover': {
              bgcolor: '#8a9ffd', 
              color: 'white',
            },
          }}
          
          >{props.label}</Button>
     
      </HtmlTooltip>
    </div>
  )
}

function CustomizedTooltips(props) {
  return (
    <div>

      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{props.message}</Typography>
          
          </React.Fragment>
        }
      >
            <Button
                href={props.link}
                target='_blank'
                style={{
                  width: '100%',
                  height: '50px',
                  color: '#142364',
                  fontSize: '18px'
                }}
                className="gradient"
              >
                 
                {props.label}
              </Button>
      </HtmlTooltip>
    </div>
  )
}


export {
  ToolTip,
  CustomizedTooltips
}