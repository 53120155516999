import React, { useEffect } from "react"
import {
  Grid,
  Typography,
} from "@mui/material/"
import {
  ImageCard
} from "../components/cards"

import WF_IMG_1 from "../assets/images/workflow/DigiTech1.jpg"
import WF_IMG_2 from "../assets/images/workflow/DigiTech2.jpg"
import WF_IMG_3 from "../assets/images/workflow/DigiTech3.jpg"
import WF_IMG_4 from "../assets/images/workflow/DigiTech4.jpg"
import WF_IMG_5 from "../assets/images/workflow/DigiTech5.jpg"
import WF_IMG_6 from "../assets/images/workflow/DigiTech6.jpg"
export default function Workflow() {
 
  useEffect(() => {
    
  }, [])

 

  return (
   
    <div>
      <Grid container xs={10} spacing={3} className="workflowContainer" >

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>AUDIT WORKBOOK</Typography>}
             image={WF_IMG_1}
             para={"Collecting, importing, and transforming data fro analysis and storage. Essential for effective data management and insights."}
            />
        </Grid>

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>Data Status Tracker</Typography>}
             image={WF_IMG_2}
             para={"Process of finding specific information within a collection of data. Enables efficient data retrieval and access to relevant insights"}
            />
        </Grid>

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>Dormant Account Review - Quarterly</Typography>}
             image={WF_IMG_3}
             para={"Using language processing to enhance information retrieval based on user queries."}
            />
        </Grid>

      </Grid>

      <Grid container xs={10} spacing={3}  className="workflowContainer" >

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>ENTERPRISE ANALYTICS</Typography>}
             image={WF_IMG_4}
             para={"Frameworks or algorithms designed to optimize the process of information retrieval and ranking."}
            />
        </Grid>

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>EXTERNAL DATA LOAD</Typography>}
             image={WF_IMG_5}
             para={"Frameworks for efficient and accurate data extraction, transformation, and loading into a centralized system."}
            />
        </Grid>

        <Grid item xs={4} >
            <ImageCard 
             title={<Typography>FEEDER RECON FOR VARIANCE</Typography>}
             image={WF_IMG_6}
             para={"You can launch a standalone cluster either manually, by starting a master and workers by hand, or using our provided launch scripts."}
            />
        </Grid>
        
      </Grid>
      </div>

  )
}
