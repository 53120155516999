import React from "react"
import { Grid } from "@mui/material/"
import WorkflowContent from "./content/workflow"

export default function Workflow() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
    >
  
      <Grid item xs={12} className="marginCenter">
        <WorkflowContent />
      </Grid>

    </Grid>
  )
}
