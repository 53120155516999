import React, {useState,useEffect,useReducer} from "react"
import {
  Grid,
  ListItem,
  TextField,
  List,
  ListItemText,
  Typography,
  Button
} from "@mui/material"
import { useDropzone } from "react-dropzone"
import {
  DOWNLOAD_ICON
} from "../assets/icons/IconList"
import { useForm } from "react-hook-form"
import PDF_ICON from "../assets/images/PDF-Icon-white.png"
import PNG_ICON from "../assets/images/PNG_ICON.png"
import JPG_ICON from  "../assets/images/JPG_ICON.png"
import axios from "axios"

function DataUpload() {

  useEffect(() => {
   
  }, [])

  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [files, setFiles] = useState([])
  const [messageData, setMessageData] = useState([])
  const [formProcessing, setFormProcessing] = useState(false)
  const [message, setMessage] = useState(false)
  const [hideLoader, setHideLoader] = useState(false)
  const [open, setOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false)
  const [isUpload, setIsUpload] = React.useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const initForm = {
    caseNumber: "",
    assignedUser: "",
    receivedOn: "",
    requestedBy: "",
  }

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  )

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
  useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
      'application/json': [],
      'text/txt': [],
      
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })

  const thumbOrginalFile = files.map(file => (
    <div  key={file.name}  >
      <div style={{ borderRadius: '10px'}} >
        <img
          src={file.preview}
          style={{borderRadius: '10px', marginTop: '3px' }}
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ))


const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  <li key={file.path}>
    {file.path} - {file.size} bytes
    <ul>
      {errors.map((e) => (
        <li key={e.code}>{e.message}</li>
      ))}
    </ul>
  </li>
))

  const handleUploadFiles = (form) => {
    console.log("Form Data" , form)
      let data = JSON.stringify({"filename":acceptedFiles[0].name})
      setHideLoader(false)
      setFormProcessing(true)
        
      let config = {
        method: 'post',
        url: `${BASE_URL}/upload/request`,
        headers: { 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
          'Content-Type': 'application/json'
        },
        data : data
      }

      axios.request(config)
      .then(function (response) {
      
       
        let formData = new FormData()
        
        formData.append("file", acceptedFiles[0])
        formData.append("signedUrl", response.data.signedUrl)


              let config2 = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: `${BASE_URL}/upload`,
                  headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
                    'Content-Type': 'multipart/form-data'
                  },
                  data: formData
                }

                axios.request(config2)
                .then(function (response) {

                  console.log(response.data)
                  setMessageData(response.data)

                        let metaData = JSON.stringify(   {
                          "requestedBy": "John Doe",
                          "status": "pending",
                          "meta": {
                              "dataset": {
                                "name": form.datasetName,
                                "owner": form.datasetOwner,
                                "source": form.datasetSource,
                                "description": form.datasetDescription,
                              },
                              "type": "ADHOC",
                              "department": "Data Analytics"
                          }
                      })

                        let id = response.data.id
                      
                        let config3 = {
                          method: 'put', // Use to be patch
                          maxBodyLength: Infinity,
                          url: `${BASE_URL}/record/${id}`,
                          headers: {
                            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
                              'Content-Type': 'application/json'
                          },
                          data: metaData
                        }
                  

                        axios.request(config3)
                        .then(function (response) {
                      
                          setHideLoader(true)
                          setMessage(true)
                          
                        
      
                        })
                        .catch(function (error) {
                          console.log(error);
                        })
                
                })
                .catch(function (error) {
                  console.log(error);
                })

      })
      .catch(function (error) {
        console.log(error);
      });

  }



  const resetForm = () => {
    setFormProcessing(false) 
  }

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path} style={{ color: "#006BB6" }}>
      &nbsp; {file.path} - {file.size} bytes
    </span>
  ))

  const ProcessingFormSubmission = () => {
    
    return(
      <Grid container xs={6} >
          
      
        {
          hideLoader === true ? '' :
          <Grid item xs={12} className="fadePanel"  style={{margin: '100px auto'}} >
     
                <span >
                <div class="multi-ripple" >
                  <div></div>
                  <div></div>
                  
                </div>
            
                </span>
             
      
            </Grid>
      
        }
        
        {
          message === true ? 
          <SubmissionMessage />
          :
          ''
        }
      
      

      </Grid>

    )
  }

  const SubmissionMessage = () => {
    return(
      <div className="fadePanel message"  >
      <List>
          <ListItem>
            
            <ListItemText
                primary={<Typography style={{color: '#248f24', fontSize: '22px'}} >Dataset has been created successfully</Typography>}
              />
          </ListItem>
          <div className="spacer10" />
          <ListItem>
            <ListItemText
              primary={"Id"}
              secondary={messageData.id}
            />
          
      
          </ListItem>
          <div className="spacer10" />

          <ListItem>

          <ListItemText
              primary={"File Name"}
              secondary={messageData.filename}
            />
            

          </ListItem>
     
            <div className="spacer20" />
          <ListItem>
            <ListItemText
                primary={<Button variant="contained" style={{float: 'right', height: '50px', marginTop: '50px'}} onClick={() => resetForm()}>Upload Another Dataset</Button>}
              />
          </ListItem>
          
        </List>
    
        
      </div>
    )
  }




  return (
    <div style={{ height: "75vh"}}>
      <Grid container xs={12}  >
        <Grid xs={6} >
          <List style={{ paddingLeft: "25px", marginTop: "10px" }}>
            <ListItem>
          
              <ListItemText
                primary={<div className="pageTitle">Data Upload Page</div>}
              />
            </ListItem>
          </List>
        </Grid>
   
      <Grid container xs={12} padding={4} style={{margin: '0 auto'}} >
        <Grid xs={6} 

          style={{
            margin: "0 auto",
            border: "dashed 2px #5ab3a8",
            borderRadius: "5px",
            padding: "25px",
            textAlign: "center",
            cursor: "pointer",
          }}
          className="dragDropArea css-selector"
        >
       
            <div {...getRootProps({ className: "dropzone" })} style={{marginTop: '10px'}} >
              <input {...getInputProps()} />

              <div className="floating" >
               <DOWNLOAD_ICON />
              </div>

              <Typography
                style={{ color: "#006bb6" }}
                variant="h6"
              >
                
                Click to upload or drag and drop
              </Typography>
              <div className="spacer20" />
  
              <Typography
                sx={{ fontStyle: "italic" }}
                variant="subtitle2"
                style={{ color: "#999"}}
              >
                (Only image and document files will be accepted)
              </Typography>
              <div className="spacer10" />
              <img src={PDF_ICON} style={{width: '35px'}} />
              &nbsp; &nbsp; 
              <img src={PNG_ICON} style={{width: '35px'}} />
              &nbsp; &nbsp; 
              <img src={JPG_ICON} style={{width: '35px'}} />
          
            </div>
            <div className="spacer20" />
              <Typography style={{ color: "#adf0ff" }}>
                {acceptedFileItems}
              </Typography>

          </Grid>
       
                            
       
      {
        formProcessing === true  ? 
        <ProcessingFormSubmission />
        :
      
        <Grid container xs={6} className="fadePanel formSection" spacing={3} >
          <Grid containenr xs={10} style={{margin: '0 auto'}}   >
          <Grid item xs={8}>
            <Typography className="formListTypography">
              Dataset Name
            </Typography>
            
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Please enter the Dataset Name"
              onChange={(e) => e.target.value}
              {...register("datasetName", { required: "Required Field" })}
              error={!!errors?.datasetName}
              helperText={
                errors?.datasetName ? errors.datasetName.message : null
              }
            />
          </Grid>
          <div className="spacer10" />

          <Grid xs={8}>
            <Typography className="formListTypography">
              Dataset Owner
            </Typography>
          </Grid>
          <Grid xs={11}>
            <TextField
              fullWidth
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Please enter the Dataset Owner"
              onChange={(e) => e.target.value}
              {...register("datasetOwner", { required: "Required Field" })}
              error={!!errors?.datasetOwner}
              helperText={
                errors?.datasetOwner ? errors.datasetOwner.message : null
              }
            />
          </Grid>
          <div className="spacer10" />
          <Grid xs={8}>
            <Typography className="formListTypography">
              Dataset Source
            </Typography>
          </Grid>
          <Grid xs={11}>
            <TextField
              fullWidth
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Please enter the Dataset Source"
              onChange={(e) => e.target.value}
              {...register("datasetSource", { required: "Required Field" })}
              error={!!errors?.datasetSource}
              helperText={
                errors?.datasetSource ? errors.datasetSource.message : null
              }
            />
          </Grid>
          <div className="spacer10" />
          <Grid xs={8}>
            <Typography className="formListTypography">
              Dataset Description
            </Typography>
          </Grid>
          <Grid xs={11}>
            <TextField
              fullWidth
              variant="filled"
              color="secondary"
              className="formListTextbox"
              placeholder="Please enter the Dataset Description"
              onChange={(e) => e.target.value}
              {...register("datasetDescription", { required: "Required Field" })}
              error={!!errors?.datasetDescription}
              helperText={
                errors?.datasetDescription ? errors.datasetDescription.message : null
              }
            />
          </Grid>
         
            <Grid xs={11}>
             <div className="spacer30" />
             <div className="spacer20" />
                  <Button 
                    variant="outlined" 
                    style={{
                      height: '50px',
                      color: '#fff',
                      fontSize: '15px',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      width: '40%',
                      height: '50px',
                      background: '#2f388e',
                      color: '#fff',
                      float: "right",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: '17px'
                    }}
                    onClick={handleSubmit(handleUploadFiles)}

                  >
                 
                    Submit
                  </Button>

        
            </Grid>

          </Grid>
  
        </Grid>
      }
      </Grid>
      </Grid>
    </div>
  )
}

export default DataUpload;
