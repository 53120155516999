import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Card, CardHeader} from '@mui/material/'
import FullScreenMode from "../../../components/fullScreenModal"
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
 
        <CardMedia
          sx={{ height: 140 }}
          image={`${process.env.PUBLIC_URL}${props.demoJson.overviewImage}`}
          title={props.demoJson.title}
        />

      <CardContent style={{height: '75px', padding: '20px'}} >

        <Typography gutterBottom variant="body2" style={{color: '#0f183f', fontSize: '20px'}} >
        {props.demoJson.model_name}
        </Typography>
        
      </CardContent>
       <CardActions style={{background: '#e9edff'}}>
      <IconButton  style={{margin: '0 auto', color: '#fff', textAlign: 'center'}}  className="expandMoreGradient" >
          <ExpandMore
            style={{color: '#fff'}}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
      </IconButton>

      </CardActions> 
       
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{background: '#e9edff'}} >
        <div className='spacer30' />
            <Typography  ><b>Owner</b></Typography>
            <Typography variant="body2" style={{fontSize: '18px'}} >
              {props.demoJson.owner}
            </Typography>
            <div className='spacer10' />

            <Typography ><b>Description</b></Typography>
              <Typography paragraph >
            {props.demoJson.description}
            </Typography> 

          <div className='spacer20' />

           <FullScreenMode
            title={props.demoJson.model_name}
            owner={props.demoJson.owner}
            desc={props.demoJson.description}
            source={props.demoJson.source}
          /> 

        </CardContent>
      </Collapse>
    </Card>
  );
}
