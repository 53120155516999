// Schema Guide:
// Mandatory: title, subtitle, description, useCase, overviewImage, benefits, talktrackPdf


const demosJson = [
  { "model_name": "Military Equipment Maintenance Prediction Model",
    "title": "Military Equipment Maintenance Prediction Model",
    "subtitle": "This model predicts maintenance needs for military equipment based on usage and sensor data.",
    "description": "This model predicts maintenance needs for military equipment based on usage and sensor data.",
    "useCase": "Data Fusion, Data Discovery ",
    "overviewImage": "/images/1.jpeg",
    "owner": "Defense Data Analytics Team",
    "source": "sensor_data , maintenance_logs",
    "image": "/images/1.jpeg",
    
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate data sets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Applying ABAC to the frame level of images and videos to make multi-media data instantly releasible and searchable to a diverse user base."
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured or unstructured data, while logically seperating access credentials at a fine-grained level for a diverse user base"
      },
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag and drop environment with pre loaded recipes to enable collaboration betwene non technical and technical users alike. Lower the barier of entry to create powerful analyitcs in no-code to high-code data science platform where traning time decreases from months to hours. "
      },
      {
        "title": "Mission Accelerators",
        "description": "Leverage high performing TRL 6 and 7 machine learning models in an air-gapped, readily deployable environment to rapidly increase ML and AI development. Work with SME's to apply transfer learning with your data to tailor to your specific use case and ramp up to a TRL8 or 9 for production grade deployment."
      }
    ],
    "video": "",
    "talktrackPdf": "https://docs.google.com/presentation/d/1mDkf9W8TR4mc_fN3bO1ZotH7nYK3-d6a/edit?usp=drive_link&ouid=104262004862347650303&rtpof=true&sd=true",
    "customerdata": "This capability demonstration was initially created to persue a FEDSIM deal with NSIN and NIWC Lant, since then has been shown to a significant number of groups that have aquisition and a need for organizing requirements gathering. These groups include the following yyyy and zzzz including abc, efg, hij, klm. \nTradeshows: xxx, yyy, zzz and kkk.",
    "relevantcustomers": "Navy, Army, Air Force, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Military Equipment Maintenance Prediction Model",
    "model_name": "Vehicle Maintenance Prediction Model",
    "description": "This model predicts maintenance needs for military vehicles based on telemetry data and historical maintenance records.",
    "owner": "Military Vehicle Maintenance Team",
    "last_updated": "09-25-2023",
    "overviewImage": "/images/2.jpeg",
    "version_information": "Version 1.2",
    "data_sources_used": ["telemetry_data", "maintenance_records"],
    "data_governance_classifications": ["Sensitive"],
    "data_quality": {
        "accuracy": "92%",
        "completeness": "94%",
        "consistency": "89%",
        "reliability": "93%",
        "timeliness": "95%"
    },
    "data_transformation_processing_steps": "Data preprocessing includes feature engineering and anomaly detection.",
    "data_lineage": {
        "input_data_sources": ["telemetry_data", "maintenance_records"],
        "output_data_sources": ["maintenance_predictions"]
    },
    "dependencies": ["Telemetry Data Ingestion Pipeline", "Vehicle Maintenance Database"],
    "model_code_script_url": "https://github.com/military-team/vehicle-maintenance-model.py",
    "compliance_regulations": ["Defense Information Assurance Certification and Accreditation Process (DIACAP)"],
    "usage_documentation_url": "https://military-example.com/vehicle-maintenance-model-docs",
    "test_results_validation": {
        "test_type": "Cross-Validation",
        "metrics": {
            "precision": 0.91,
            "recall": 0.88,
            "F1_score": 0.89
        },
        "description": "The model underwent 5-fold cross-validation using a labeled dataset of military vehicle telemetry data and maintenance records. The following performance metrics were obtained:\n\n- Precision: 0.91\n- Recall: 0.88\n- F1 Score: 0.89\n\nThese results demonstrate reliable predictive accuracy."
    },
    "metadata_tags_keywords": ["Military Vehicle Maintenance", "Predictive Maintenance", "Telemetry Data"],
    "access_permissions": {
        "read": ["Military Vehicle Maintenance Team", "Logistics Team"],
        "edit": ["Military Vehicle Maintenance Team"],
        "execute": ["Logistics Team"]
    },
    "audit_trail": [
        {
            "change_date": "09-20-2023",
            "change_description": "Enhanced feature selection for improved accuracy."
        },
        {
            "change_date": "09-22-2023",
            "change_description": "Optimized model hyperparameters."
        }
    ],
    "related_models": ["Equipment Failure Prediction Model"],
    "feedback_comments": [
        {
            "comment_date": "09-26-2023",
            "comment_author": "Jane Doe",
            "comment_text": "The model has been valuable in reducing vehicle maintenance downtime."
        }
    ]
  },
  {
    "model_name": "Threat Detection Model",
    "description": "This model detects security threats and anomalies in military network traffic and log data.",
    "owner": "Defense Cybersecurity Team",
    "overviewImage": "/images/3.jpeg",
    "last_updated": "09-28-2023",
    "version_information": "Version 1.5",
    "data_sources_used": ["network_traffic_data", "log_data"],
    "data_governance_classifications": ["Highly Sensitive"],
    "data_quality": {
        "accuracy": "94%",
        "completeness": "96%",
        "consistency": "92%",
        "reliability": "95%",
        "timeliness": "97%"
    },
    "data_transformation_processing_steps": "Data preprocessing involves feature extraction and anomaly pattern recognition.",
    "data_lineage": {
        "input_data_sources": ["network_traffic_data", "log_data"],
        "output_data_sources": ["threat_detection_alerts"]
    },
    "dependencies": ["Network Traffic Data Feed", "Cybersecurity Log Repository"],
    "model_code_script_url": "https://github.com/cybersecurity-team/threat-detection-model.py",
    "compliance_regulations": ["Department of Defense Cybersecurity Regulations"],
    "usage_documentation_url": "https://cybersecurity-example.com/threat-detection-model-docs",
    "test_results_validation": {
        "test_type": "Validation against Historic Threats",
        "metrics": {
            "precision": 0.95,
            "recall": 0.92,
            "F1_score": 0.93
        },
        "description": "The model was tested against historical security threats and anomalies in military network traffic. The following performance metrics were achieved:\n\n- Precision: 0.95\n- Recall: 0.92\n- F1 Score: 0.93\n\nThese results indicate strong threat detection capabilities."
    },
    "metadata_tags_keywords": ["Threat Detection", "Cybersecurity", "Network Security"],
    "access_permissions": {
        "read": ["Defense Cybersecurity Team", "Network Operations Team"],
        "edit": ["Defense Cybersecurity Team"],
        "execute": ["Network Operations Team"]
    },
    "audit_trail": [
        {
            "change_date": "09-24-2023",
            "change_description": "Enhanced anomaly detection algorithms."
        },
        {
            "change_date": "09-26-2023",
            "change_description": "Incorporated new threat intelligence feeds."
        }
    ],
    "related_models": ["Security Incident Response Model"],
    "feedback_comments": [
        {
            "comment_date": "09-29-2023",
            "comment_author": "Robert Johnson",
            "comment_text": "This model has significantly improved our network security posture."
        }
    ]
  },
  {
    "model_name": "Satellite Image Analysis Model",
    "description": "This model analyzes satellite images to identify critical infrastructure, monitor changes, and provide geospatial intelligence to military decision-makers.",
    "owner": "Geospatial Intelligence Team",
    "last_updated": "10-10-2023",
    "overviewImage": "/images/4.jpeg",
    "version_information": "Version 1.2",
    "data_sources_used": ["satellite_imagery", "geospatial_data"],
    "data_governance_classifications": ["Geospatially Sensitive"],
    "data_quality": {
        "accuracy": "92%",
        "completeness": "96%",
        "consistency": "90%",
        "reliability": "94%",
        "timeliness": "96%"
    },
    "data_transformation_processing_steps": "Data preprocessing includes image segmentation and object recognition.",
    "data_lineage": {
        "input_data_sources": ["satellite_imagery", "geospatial_data"],
        "output_data_sources": ["geospatial_intelligence_reports"]
    },
    "dependencies": ["Satellite Image Repository", "Geospatial Data Service"],
    "model_code_script_url": "https://github.com/geospatial-intelligence-team/satellite-image-analysis-model.py",
    "compliance_regulations": ["Geospatial Data Handling Guidelines"],
    "usage_documentation_url": "https://geospatial-example.com/satellite-image-analysis-model-docs",
    "test_results_validation": {
        "test_type": "Change Detection Analysis",
        "metrics": {
            "infrastructure_identification": "87%",
            "change_monitoring_accuracy": "89%"
        },
        "description": "The model underwent change detection analysis and achieved an 87% accuracy in identifying critical infrastructure and an 89% accuracy in monitoring changes in geospatial data."
    },
    "metadata_tags_keywords": ["Satellite Image Analysis", "Geospatial Intelligence", "Change Detection"],
    "access_permissions": {
        "read": ["Geospatial Intelligence Team", "Military Commanders"],
        "edit": ["Geospatial Intelligence Team"],
        "execute": ["Military Commanders"]
    },
    "audit_trail": [
        {
            "change_date": "10-08-2023",
            "change_description": "Enhanced object recognition algorithms."
        },
        {
            "change_date": "10-09-2023",
            "change_description": "Added real-time geospatial data feeds."
        }
    ],
    "related_models": ["Terrain Analysis Model"],
    "feedback_comments": [
        {
            "comment_date": "10-11-2023",
            "comment_author": "Emily Davis",
            "comment_text": "This model has significantly improved our geospatial intelligence capabilities."
        }
    ]
  },
  {
    "model_name": "Satellite Image Analysis Model",
    "description": "This model analyzes satellite images to identify critical infrastructure, monitor changes, and provide geospatial intelligence to military decision-makers.",
    "owner": "Geospatial Intelligence Team",
    "last_updated": "10-10-2023",
    "overviewImage": "/images/5.jpeg",
    "version_information": "Version 1.2",
    "data_sources_used": ["satellite_imagery", "geospatial_data"],
    "data_governance_classifications": ["Geospatially Sensitive"],
    "data_quality": {
        "accuracy": "92%",
        "completeness": "96%",
        "consistency": "90%",
        "reliability": "94%",
        "timeliness": "96%"
    },
    "data_transformation_processing_steps": "Data preprocessing includes image segmentation and object recognition.",
    "data_lineage": {
        "input_data_sources": ["satellite_imagery", "geospatial_data"],
        "output_data_sources": ["geospatial_intelligence_reports"]
    },
    "dependencies": ["Satellite Image Repository", "Geospatial Data Service"],
    "model_code_script_url": "https://github.com/geospatial-intelligence-team/satellite-image-analysis-model.py",
    "compliance_regulations": ["Geospatial Data Handling Guidelines"],
    "usage_documentation_url": "https://geospatial-example.com/satellite-image-analysis-model-docs",
    "test_results_validation": {
        "test_type": "Change Detection Analysis",
        "metrics": {
            "infrastructure_identification": "87%",
            "change_monitoring_accuracy": "89%"
        },
        "description": "The model underwent change detection analysis and achieved an 87% accuracy in identifying critical infrastructure and an 89% accuracy in monitoring changes in geospatial data."
    },
    "metadata_tags_keywords": ["Satellite Image Analysis", "Geospatial Intelligence", "Change Detection"],
    "access_permissions": {
        "read": ["Geospatial Intelligence Team", "Military Commanders"],
        "edit": ["Geospatial Intelligence Team"],
        "execute": ["Military Commanders"]
    },
    "audit_trail": [
        {
            "change_date": "10-08-2023",
            "change_description": "Enhanced object recognition algorithms."
        },
        {
            "change_date": "10-09-2023",
            "change_description": "Added real-time geospatial data feeds."
        }
    ],
    "related_models": ["Terrain Analysis Model"],
    "feedback_comments": [
        {
            "comment_date": "10-11-2023",
            "comment_author": "Emily Davis",
            "comment_text": "This model has significantly improved our geospatial intelligence capabilities."
        }
    ]
  },
  {
    "model_name": "Satellite Image Analysis Model",
    "description": "This model analyzes satellite images to identify critical infrastructure, monitor changes, and provide geospatial intelligence to military decision-makers.",
    "owner": "Geospatial Intelligence Team",
    "last_updated": "10-10-2023",
    "overviewImage": "/images/6.jpeg",
    "version_information": "Version 1.2",
    "data_sources_used": ["satellite_imagery", "geospatial_data"],
    "data_governance_classifications": ["Geospatially Sensitive"],
    "data_quality": {
        "accuracy": "92%",
        "completeness": "96%",
        "consistency": "90%",
        "reliability": "94%",
        "timeliness": "96%"
    },
    "data_transformation_processing_steps": "Data preprocessing includes image segmentation and object recognition.",
    "data_lineage": {
        "input_data_sources": ["satellite_imagery", "geospatial_data"],
        "output_data_sources": ["geospatial_intelligence_reports"]
    },
    "dependencies": ["Satellite Image Repository", "Geospatial Data Service"],
    "model_code_script_url": "https://github.com/geospatial-intelligence-team/satellite-image-analysis-model.py",
    "compliance_regulations": ["Geospatial Data Handling Guidelines"],
    "usage_documentation_url": "https://geospatial-example.com/satellite-image-analysis-model-docs",
    "test_results_validation": {
        "test_type": "Change Detection Analysis",
        "metrics": {
            "infrastructure_identification": "87%",
            "change_monitoring_accuracy": "89%"
        },
        "description": "The model underwent change detection analysis and achieved an 87% accuracy in identifying critical infrastructure and an 89% accuracy in monitoring changes in geospatial data."
    },
    "metadata_tags_keywords": ["Satellite Image Analysis", "Geospatial Intelligence", "Change Detection"],
    "access_permissions": {
        "read": ["Geospatial Intelligence Team", "Military Commanders"],
        "edit": ["Geospatial Intelligence Team"],
        "execute": ["Military Commanders"]
    },
    "audit_trail": [
        {
            "change_date": "10-08-2023",
            "change_description": "Enhanced object recognition algorithms."
        },
        {
            "change_date": "10-09-2023",
            "change_description": "Added real-time geospatial data feeds."
        }
    ],
    "related_models": ["Terrain Analysis Model"],
    "feedback_comments": [
        {
            "comment_date": "10-11-2023",
            "comment_author": "Emily Davis",
            "comment_text": "This model has significantly improved our geospatial intelligence capabilities."
        }
    ]
  }
]

export default demosJson;