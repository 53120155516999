import React from "react"
import { Grid } from "@mui/material/"
import DataCatalogContent from "./content/dataCatalog"

export default function DataCatalog() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="searchContainer"
    >
  
      <Grid item xs={12} className="marginCenter">
        <DataCatalogContent />
      </Grid>

    </Grid>
  )
}
