import React from "react"
import { Grid } from "@mui/material"
import FooterLogo from "../assets/images/Powered-By-SAIC.png"

const Footer = () => {
  return (  
      <Grid container xs={12}  >
        <Grid item xs={12} >
          <div className="spacer30" />
          <img src={FooterLogo} alt="SAIC" style={{marginTop: '0px', paddingLeft: '20px', width: '115px'}} />
         </Grid>
      </Grid>

  )
}

export default Footer
