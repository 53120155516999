import React, { useEffect } from "react"
import {
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText
} from "@mui/material/"
import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"
import { Pie, Bar } from "react-chartjs-2"
import StatusTable from "../components/table"
import axios from "axios"


export default function Dashboard() {

  useEffect(() => {
    getTableData()
}, [])

const [dataFiles, setDataFiles] = React.useState([])
// const [approvedCount, setApprovedCount] = React.useState()

const getTableData =  () => {

  let data = JSON.stringify({
    "limit" : 50,
    "next": ""
  })

  let config = {
    method: 'post',
    url: 'http://35.84.38.192:3000/record/list',
    headers: { 
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.rs1-a0IbdNh4p73jLHQ6xVFUBn2Xn2CLJHA0fbGQ6ko', 
      'Content-Type': 'application/json'
    },
    data: data
  }

  axios.request(config)
  .then(function (response) {
   console.log(response.data.Items)
     setDataFiles(response.data.Items)

  })
  .catch(function (error) {
    console.log(error);
  })

}

const pendingCount = () => {
  let pendingCount = 0

  dataFiles.map((item)=>{
    
    if(item.status === 'pending'){
      pendingCount++
      console.log(pendingCount)
      
    }
  })

    return (
      <span>{pendingCount}</span>
    )
  
}

const approvedCount = () => {
  let approvedCount = 0

  dataFiles.map((item)=>{
    
    if(item.status === 'approved'){
      approvedCount++
      console.log(approvedCount)
      
    }
  })

    return (
      <span class="circleAnimation">{approvedCount}</span>
    )
  
}



  const chartData = {
    labels: [ 'Total Approved', 'ADHOC','DB Connections','Streams','FTP','Model Repo'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
        {
          label: 'Status',
          data: [
            20,30,90,300,200,20
            
        ],
          // you can set indiviual colors for each bar
          backgroundColor: [
            '#6384f2', 
            '#c144e3',
            '#63d3f2',
          ],
          borderWidth: 1
        }
    ]
}
 

  return (
   
      <Grid container xs={12} className="governanceContainer"  >

        <Grid container xs={12} >

         <Grid item xs={2} className="governanceSection" style={{border: '5px solid #141f51', borderRadius: '5px'}} >
            <div className="spacer30" />
            <Typography variant="h6" style={{color: '#5a7bf3', textAlign: 'center'}} >
              Weekly Statistics
            </Typography>
            <div className="spacer30" />
            <List >
              <ListItem style={{margin: '0 auto', textAlign: 'center'}} >
                <ListItemText 
                  primary={<span style={{color: '#fff', fontSize: '20px' }}  >Approved</span>}
                  secondary={<span  style={{color: '#32d14c', fontSize: '25px' }}  >{
                    approvedCount()
              
                  }</span>}
                />
              </ListItem>

              <ListItem style={{margin: '0 auto', textAlign: 'center'}}>
                <ListItemText 
                  primary={<span style={{color: '#fff',fontSize: '20px'  }}  >Pending</span>}
                  secondary={<span style={{color: '#c6d132',fontSize: '25px' }}  >{
                    pendingCount()
                  }</span>}
                />
              </ListItem>
              
              <ListItem style={{margin: '0 auto', textAlign: 'center'}} >
                <ListItemText 
                  primary={<span style={{color: '#fff',fontSize: '20px'  }}  >Rejected</span>}
                  secondary={<span style={{color: '#d13f32',fontSize: '25px' }}  >0</span>}
                />
              </ListItem>
           
            </List>
          
         </Grid>
  
        <Grid container xs={10} className="governanceSection" style={{margin: '0 auto', borderRadius: '10px'}} >

          <Grid item xs={6} >

            <List>
              <ListItem>
                <ListItemText 
                  primary={<span style={{color: '#ccd7ff' }}  >Information Overview</span>}
                  secondary={<span style={{color: '#5a7bf3' }}  >Overview of entire dataset collection</span>}
                />
              </ListItem>
            </List>
         
          </Grid>

          <Grid item xs={6} >
            <Typography>
              
               <Link href="#" style={{color: '#5a7bf3', textDecoration: 'none', float: 'right', marginTop: '25px',paddingRight: '25px', fontSize: '14px'}}>
                <i>Charts & Graphs</i>
               </Link>
              
            </Typography>
          </Grid>

          <Grid item xs={8}  >
              <Bar
               style={{width: '100%', height: '100%',color: '#fff', background: '#fff', padding: '10px', borderRadius: '10px'}}
                data={chartData}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Dataset Collections"
                    },
                    legend: {
                      labels: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        padding: 20,
                        color: '#333'
                      }
                    }
                  }
                }}
              />
          </Grid>

          <Grid item xs={4} padding={1} >
            <Pie
                style={{width: '100%', height: '100%',background: 'transparent',padding: '10px', borderRadius: '10px'}}
                    data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: true
                        },
                        legend: {
                          labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 10,
                            color: '#fff'
                          }
                        }
                      }
                    }}
              />
          </Grid>
     

          </Grid>

        </Grid>
        <Grid item xs={12} className="spacer20" />
        
        <Grid container xs={12} className="governanceSection" style={{margin: '0 auto',borderRadius: '10px'}} >
        
            <Grid item xs={6} >

              <List>
                <ListItem>
                  <ListItemText 
                    primary={<span style={{color: '#ccd7ff' }}  >Dataset Collections</span>}
                    secondary={<span style={{color: '#5a7bf3' }}  >Overview of entire dataset collection</span>}
                  />
                </ListItem>
              </List>

              </Grid>

              <Grid item xs={6} >
              <Typography>
                
                <Link href="#" style={{color: '#5a7bf3', textDecoration: 'none', float: 'right', marginTop: '25px',paddingRight: '25px', fontSize: '14px'}}>
                  VIEW ALL DATASETS
                </Link>
                
              </Typography>
              </Grid>

              <Grid item xs={12} style={{margin: '0 auto', padding: '25px'}} >
                    <StatusTable 
                      data={dataFiles}
                    />
              </Grid>

        </Grid>

      </Grid>

  )
}
