import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material/"
import { DOWNLOAD_ICON, UPLOAD_ICON } from "../assets/icons/IconList"
import DeleteIcon from "@mui/icons-material/Delete"

import ProgressBar from "../components/progress"
import axios from "axios"

const maxLength = 20;
function nameLengthValidator(file) {
  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${maxLength} characters`,
    };
  }
  return null;
}
export default function DragAndDrop(props) {
  const [state, setState] = useState({
    dataValues: [],
  });
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [updateProgress, setUpdateProgress] = React.useState();
  const [hideButton, setHideButton] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      // validator: nameLengthValidator,
      accept: {
        "application/pdf": [".pdf", ".txt", ".doc", ".docx"],
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <span key={file.path} style={{ color: "#006BB6" }}>
      &nbsp; {file.path} - {file.size} bytes
    </span>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleDelete = () => {
    setOpen(true);
  };

  const handleDeleteConfirm = () => {
    // Perform delete operation
    setOpen(false);
  };

  const handleDeleteCancel = () => {
    setOpen(false);
  };

  const handleUploadFiles = () => {
    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    props.transView(true);
    setShowSpinner(true);
    setHideButton(true);
    props.spinner(true);

    axios
      .post(`https://nlpservices.ais.saicds.com:8003/upload`, formData, {
        headers: {
          Authorization: "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        let summary = response.data.Content;
        console.log(response.data.Content);
        setComplete(true);
        setState({
          dataValues: response.data,
        });
        props.meta(response.data.Metadata);
        setUpdateProgress(35);
        props.view(true);
        setShowSpinner(false);
        setTimeout(() => {
          setUpdateProgress(50);
        }, 3000);

        axios
          .post(`https://nlpservices.ais.saicds.com:8003/summarize`, {
            content: summary,
          })
          .then((response) => {
            setUpdateProgress(65);
            props.summary(response.data.summary);

            axios
              .post(
                `https://centcomservices.ais.saicds.com:8088/api/v1/extract_single_text`,
                {
                  text: summary,
                }
              )
              .then((response) => {
                setUpdateProgress(100);
                setComplete(false);
                props.nersData(response.data.Entities);
              });
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setFileUploaded(true);
  };

  return (
    <Grid xs={10} container style={{margin: '0 auto'}}>
      {showLoader === false ? (
        ""
      ) : (
        <div class="animation01">
          <div class="rhombus_small">
            <div class="rhombus">
              <div class="border_box">
                <span class="line line01"></span>
                <span class="line line02"></span>
                <span class="line line03"></span>
                <span class="line line04"></span>
              </div>
            </div>
          </div>
        </div>
      )}

      <Grid
        xs={12}
        item
        {...getRootProps({ className: "dropzone" })}
        style={{
          margin: "0 auto",
          border: "dashed 2px #5ab3a8",
          borderRadius: "5px",
          padding: "25px",
          textAlign: "center",
          cursor: "pointer"
        }}
        className="dragDropArea css-selector"
       
      >
        <div className="spacer" />
        <Grid item xs={12} style={{marginTop: '75px'}}  >
          <div className="floating" >
            <DOWNLOAD_ICON />
          </div>
          <div className="spacer" />
          <div>
            <input {...getInputProps()} />
            <Typography
             
              style={{ color: "#486ff2" }}
              variant="h6"
            >
              Upload File
            </Typography>
       
          </div>
          <aside>
            <Typography style={{ color: "#00acd4" }}>
              {acceptedFileItems}
              {acceptedFileItems.length > 0 && fileUploaded && (
                <IconButton onClick={handleDelete} style={{color: 'aqua'}} >
                  <DeleteIcon />
                </IconButton>
              )}

              <Dialog open={open} onClose={handleDeleteCancel}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                  <Typography>
                    Are you sure you want to delete the file?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteCancel}>Cancel</Button>
                  <Button onClick={handleDeleteConfirm} color="error" >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Typography>
            <div className="spacer" />
            <div className="spacer" />
          </aside>
        </Grid>
      </Grid>

      <div className="spacer30" />
      <Grid item xs={12}>
       
        {acceptedFileItems.length != 1 ? (
          ""
        ) : (
          <span>
            {hideButton === false ? (
              <Button
                variant="contained"
                onClick={() => handleUploadFiles()}
                style={{
                  margin: "50px auto",
                  display: "flex",
                  backgroundColor: "#3d48a6",
                  width: '40%',
                  padding: '10px',
                  color: '#fff'
                }}
              
              >
                UPLOAD
              </Button>
            ) : (
              ""
            )}
          </span>
        )}
        <div className="spacer" />
      </Grid>

      {showSpinner === false ? (
        ""
      ) : (
        <Grid item xs={12}>
          <div className="spacer30" />
          <Typography
            variant="h6"
            color="text.secondary"
            style={{ flex: 1, textAlign: "center", color: '#fff' }}
          >
            <b> Initializing ...</b>
          </Typography>
        </Grid>
      )}

      {updateProgress <= 100 ? (
        <ProgressBar
          progressUpdate={updateProgress}
          fileName={acceptedFileItems}
          
        />
      ) : (
        ""
      )}
      

    </Grid>
  )
}
