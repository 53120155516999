import * as React from 'react';
import {Button, Grid} from '@mui/material/'
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide'
import { Line } from "react-chartjs-2"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

  const chartData = {
    labels: [ 'Total Approved', 'ADHOC','DB Connections','Streams','FTP','Model Repo'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Dataset 1',
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        color: 'rgba(255, 99, 132, 0.5)'
   
      },
        {
          label: 'Status',
          data: [
            20,30,90,300,200,20
            
        ],
        borderColor: '#333',
          // you can set indiviual colors for each bar
          backgroundColor: '#6384f2',
          borderWidth: 1
        }
    ],

}

const options = {
  scales: {
    yAxes: [
      {
        gridLines: {
          color: "white"
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          color: "white"
        }
      }
    ]
  }
};
 

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <Button variant="contained" style={{color: '#fff', background: '#212e62',width: '100%',height: '40px', textAlign: 'right'}} onClick={handleClickOpen}>
        More Details
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
    
      >
        <AppBar sx={{ position: 'relative' }} className="headerContainer" >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{background: '#2f388e'}}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.title}
            </Typography>

          
          </Toolbar>
        </AppBar>

        <Grid  container xs={11} spacing={3} style={{margin: '0 auto'}} >

          <Grid item xs={6} >
            <div className='spacer30' />
                <List sx={{margin: '0 auto'}}>
                <ListItem >
                  <ListItemText primary={<Typography variant='h5' >Title</Typography>} secondary={<Typography style={{color: '#0f183f'}}>{props.title}</Typography>}  />
                </ListItem>
                <ListItem >
                  <ListItemText primary={<Typography variant='h5' >Requested By</Typography>} secondary={<Typography style={{color: '#0f183f'}}>{props.owner}</Typography>} />
                </ListItem>
               
               </List>
          </Grid>

          <Grid item xs={6} >
            <div className='spacer30' />
                <List sx={{margin: '0 auto'}}>
                <ListItem >
                  <ListItemText primary={<Typography variant='h5' >Description</Typography>} secondary={<Typography style={{color: '#0f183f'}} >{props.desc}</Typography>}  />
                </ListItem>
                <ListItem >
                  <ListItemText primary={<Typography variant='h5' >Source</Typography>} secondary={<Typography style={{color: '#0f183f'}} >
                  <Typography>{props.source}</Typography>
                  
                  </Typography>}  />
                </ListItem>
               </List>
          </Grid>

          <Grid item xs={8} >

                <Line
              style={{width: '100%',color: '#333', padding: '50px', margin: '-25px auto'}}
              data={chartData}
              color="#fff"
              options={{
                scales: {
                  x: {
                    display: true,
                    title: {
                      display: true,
                      text: 'Month'
                    },
                    color: '#333'
                  },
                  y: {
                    display: true,
                    title: {
                      display: true,
                      text: 'Value'
                    }
                  }},
                plugins: {
                  title: {
                    display: true,
                    text: "Dataset Collections"
                  },
                  legend: {
                    labels: {
                      usePointStyle: true,
                      pointStyle: 'circle',
                      padding: 20,
                      color: '#333',
                      background: '#333'
                    }
                  },
                  
                },
                
              }}
              />

          </Grid>

          <Grid item xs={4} >
               <List sx={{margin: '0 auto'}}>
                <ListItem >
                  <ListItemText primary={<Typography variant='h5' >About the Model</Typography>} secondary={<Typography style={{color: '#0f183f'}} >{props.desc}</Typography>}  />
                </ListItem>
           
               </List>


          </Grid>


        </Grid>
    
    
        
      </Dialog>
    </div>
  )
}
