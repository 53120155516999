import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { StyledEngineProvider } from "@mui/material/styles"
import AppWrapper from "./template/appTemplate"
import "./assets/sheets/main.scss"
import Dashboard from "./Dashboard"
import Workflow from "./Workflow"
import DataCatalog from "./DataCatalog"
import NatLangSearch from "./NaturalLangSearch"
import NLPUpload from "./NLPupload"
import DataUpload from "./DataUpload"
import Governance from "./Governance"
import Admin from './Admin'
import Tools from './Tools'
import DemoListPage from "./content/ModelRegistry/Demo List/DemoListPage"

import { DemoProvider } from './content/ModelRegistry/DemoContext'

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <DemoProvider >
      <div className="appContainer">
        <AppWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<Dashboard />} />
              <Route path="/workflow"  element={<Workflow />} />
              <Route path="/dataCatalog"  element={<DataCatalog />} />
              <Route path="/natLangSearch"  element={<NatLangSearch />} />
              <Route path="/NLPupload"  element={<NLPUpload />} />
              <Route path="/dataUpload"  element={<DataUpload />} />
              <Route path="/governance"  element={<Governance />} />
              <Route path="/modelRegistry" element={<DemoListPage />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/tools" element={<Tools />} />
            </Routes>
          </BrowserRouter>
        </AppWrapper>
      </div>
      </DemoProvider>
    </StyledEngineProvider>
  )
}

export default App
