import React from "react"
import { Button, Grid } from "@mui/material/"
import DashboardContent from "./content/dashboard"
import {
  RIGHT_ARROW_ICON
} from "./assets/icons/IconList"

export default function Dashboard() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
 
     <Grid item xs={12} className="marginCenter">
        <DashboardContent />
      </Grid>

    </Grid>
  )
}
