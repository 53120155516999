import React, { useEffect, useContext, useState, useReducer } from "react"
import {
  Grid,
  TextField,
  List,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material/"
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  SEARCH_ICON,
  HOME_ICON
} from "../assets/icons/IconList"
import { getFoiaCaseData } from "../service/services";
import ConfidenceSlider from "../components/slider";
import MyContext from "../myContext";
import { SearchResultsList } from "../components/list";

export default function NatLangSearch() {
 
  const { summary } = useContext(MyContext);
  useEffect(() => {
    retrieveData();
    console.log(summary);
    function onKeyUp(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        document.getElementById("searchBtn").click();
      }
    }
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const [caseSelected, setSelectedCase] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const [showSelectedButton, setShowSelectedButton] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [processMessage, setProcessMessage] = React.useState(false);
  const [confidenceGage, setConfidenceGage] = React.useState(0);

  const [state, setState] = useState({
    data: [],
    cases: [],
    question: "",
    showCards: false,
    filterOption: "extractive",
    extractiveView: false,
    generativeView: false,
    dropDownValue: "",
    hideTyping: false,
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
    loading: false,
    selectedFOIACase: "1111",
  });

  // Service Call
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases,
        });
        console.log(response.data.cases);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [searchQuery, setSearchQuery] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initForm = {
    data: [],
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
  };

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  // Service Calls
  const postQuerySearch = (data) => {
    console.log("Input: ", data.questionSearch);
    console.log("Score: ", confidenceGage);

    setLoading(true);

    axios
      .post(`https://nlpservices.ais.saicds.com:8003/query`, {
        query: data.questionSearch,
        threshold: confidenceGage,
        params: {},
        debug: false,
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setSearchQuery(response.data.answers);
        setShowSearchResults(true);
        setShowSelectedButton(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postAddSelectedFiles = () => {
    axios
      .post(`https://centcomservices.ais.saicds.com:8085/addfilesbycase/`, {
        casenumber: sessionStorage.getItem("CaseNumber"),
        files: selectedFiles,
      })
      .then((response) => {
        console.log("Selected Files: ", response.data);
        setTimeout(() => {
          setProcessMessage(true);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchHandler = (data) => {
    console.log(data);

    postQuerySearch(data);
  };

  const registerOptions = {
    searchInput: {
      required: "Please enter a valid query",
      minLength: {
        value: 3,
        message: "Search query must be at least 3 characters in length",
      },
    },
  };

  // Call backs
  const handleFileSelection = (files) => {
    setSelectedFiles([...selectedFiles, files]);
  };

  const handleConfidenceSelection = (score) => {
    console.log(score);
    setConfidenceGage(score);
  };

  return (
   
    <div style={{height: '100%', minHeight: '75vh'}}>
       <Grid container xs={11}  >
        <Grid item xs={12}  >
          <List style={{paddingLeft: '25px', marginTop: '10px'}} >
            <ListItem>
            
              <ListItemText
                primary={<div className="pageTitle" >Natural Language Search</div>}
              />
            </ListItem>
          </List>
          </Grid>
        </Grid>

      <Grid container xs={10}  className="natLangSearchContainer" >
      
          <Grid
              container
              xs={8}
              className="searchBox"
            >

              
              <Grid item xs={11} >
                
                <TextField
                  className="input"
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "95%",
                  }}
                  autoFocus
                  placeholder={"Search ..."}
                  name="questionSearch"
                  onChange={(e) => e.target.value}
                  {...register("questionSearch", registerOptions.searchInput)}
                />
              </Grid>

              <Grid item xs={1} style={{marginTop: '-2px'}} >
                <IconButton
                  type="submit"
                  id="searchBtn"
                  variant="contained"
                  className="searchButton"
                  onClick={handleSubmit(searchHandler)}
                
                >
                  <SEARCH_ICON color={"#aafbf2"} />  
                 
                </IconButton>
               
              </Grid>
        </Grid>

        <Grid container xs={12} >
            <Grid item style={{ margin: "-10px auto" }}>
              <ConfidenceSlider
                retrieveConfidenceGage={handleConfidenceSelection}
              />
            </Grid>
        </Grid>

        <Grid container xs={12}   >
                <Grid item xs={10} style={{margin: '25px auto'}}>

                {loading === true ? (
                  <div class="loadingContainer">
                    <div class="loading">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  ""
                )}


                  {showSearchResults === true ? (
                  searchQuery.map((item) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      spacing={5}
                      className="marginCenter fadePanel"
                     
                    >
                      <div className="spacer10" />
                      <SearchResultsList
                        FileName={item.Name}
                        Score={item.Score}
                        Context={item.Context}
                        Path={item.Meta.path}
                        Data={item}
                        retrieveFileSelection={handleFileSelection}
                      />
                      <div className="spacer20" />
                  
                    </Grid>
                  );
                })
                ) : (
                  <span></span>
                )}

                    

                </Grid>
        </Grid>


        
      </Grid>
    </div>

  )
}
